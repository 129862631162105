import React from 'react';
import * as Sentry from '@sentry/react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import { IntercomProvider } from 'react-use-intercom';
import ErrorPage from 'src/app/components/ErrorPage';
import Auth from 'src/components/Auth';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import Routes from 'src/Routes';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import SnackbarNotifications from 'src/SnackbarNotifications';
import 'src/app/components/css/ett.css';
import '@ag-grid-community/styles/ag-grid.css';
import 'react-mosaic-component/react-mosaic-component.css';
import 'src/app/components/css/mosaic-theme-ett.css';
import 'src/app/components/css/ag-theme-ett.css';
import 'src/app/components/css/datepicker-theme-ett.css';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ModuleRegistry } from '@ag-grid-community/core';  // package is implicitly available, ignore the linter warning.
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { createTheme } from 'src/theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';

LicenseManager.setLicenseKey('CompanyName=Edge to Trade,LicensedApplication=Edge to Trade,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-035489,SupportServicesEnd=2_December_2023_[v2]_MTcwMTQ3NTIwMDAwMA==bdf9be395ec266626f93d81c71114f06');


export const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins] });


ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  ClientSideRowModelModule,
  RowGroupingModule,
  MenuModule,
  // CsvExportModule
]);

const intercomAutoBootProps = { hideDefaultLauncher: true };

const useStyles = makeStyles(() => {
  return createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    },
    snackbarRoot: {
      '& > div': {
        fontSize: '.85rem'
      }
    },
    snackbarError: {
      backgroundColor: '#84323A',
    },
    snackbarSuccess: {
      backgroundColor: '#1E6E45',
    },
  });
});


function App() {
  const classes = useStyles();
  const { settings } = useSettings();


  return (
    <ThemeProvider theme={createTheme(settings)}>
      <ConditionalWrapper
        condition={process.env.REACT_APP_USERS_LAMBDA_STAGE !== 'local'}
        wrapper={children => <Sentry.ErrorBoundary fallback={<ErrorPage />}>{children}</Sentry.ErrorBoundary>}
      >
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <IntercomProvider
              appId={process.env.REACT_APP_INTERCOM_WORKSPACE_APP_ID}
              autoBoot
              autoBootProps={intercomAutoBootProps}
            >
              <SnackbarProvider
                maxSnack={1}
                classes={{
                  root: classes.snackbarRoot,
                  variantError: classes.snackbarError,
                  variantSuccess: classes.snackbarSuccess,
                }}
              >
                <SnackbarNotifications />
                <Router history={history}>
                  <Auth>
                    <ScrollReset />
                    <Routes />
                    {/* <GoogleAnalytics /> */}
                    {/* <CookiesNotification /> */}
                    {/* <SettingsNotification /> */}
                  </Auth>
                </Router>
              </SnackbarProvider>
            </IntercomProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ConditionalWrapper>
    </ThemeProvider>
  );
}

export default App;


