import { STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { flattenObjectToPaths, unflattenPathsToObject } from '../flatpath';
import {
  createEntityAndLeaf,
  createEntityValues
} from 'src/app/slicedForm/mapping/formTreeManipulation';

/**  @typedef {import('./index.js').ProfileStruct} ProfileStruct */


export default function ProfileToForm(state) {
  const entities = {};
  const profiles = [];

  const out = () => {
    state.profiles.forEach(mapProfile);
    const mapped = {
      ...state, // allow extra properties. This might bite me later...
      entities,
      profiles,
      activeProfile: state.activeProfile,
      expressions: state.expressions,
    };
    return mapped;
  };

  /**
   * reversable
   * @param {object} node
   * @returns {keyof STRUCTURAL_TYPES}
   */
  const decideNodeType = (node) => {
    if (!node || !(typeof node === 'object')) return null;

    if ('operator' in node) return STRUCTURAL_TYPES.FILTER;
    if (STRUCTURAL_TYPES.AND in node) return STRUCTURAL_TYPES.AND;
    if (STRUCTURAL_TYPES.OR in node) return STRUCTURAL_TYPES.OR;
    if (STRUCTURAL_TYPES.SLICE_GROUP in node) return STRUCTURAL_TYPES.SLICE_GROUP;

    return null;
  };



  /**  @param {ProfileStruct} profile */
  const mapProfile = (profile) => {
    const { filters, ...rest } = profile;

    const recurse = (node) => {
      const type = decideNodeType(node);

      switch (type) {
        case STRUCTURAL_TYPES.AND:
        case STRUCTURAL_TYPES.OR:
        case STRUCTURAL_TYPES.SLICE_GROUP: {
          const { id, entity, leaf } = createEntityAndLeaf({
            type,
            tree: node[type].map(recurse)
          });
          // GROUPS have no use for an entity since SLICE refactor
          //   entities[id] = entity; 
          return leaf;
        }
        case STRUCTURAL_TYPES.FILTER: {
          const { id, entity, leaf } = createEntityAndLeaf({
            type,
            values: createEntityValues(node)
          });

          entities[id] = entity;

          return leaf;
        }
        default: {
          throw new Error(`Unknown node type "${type}", node ${JSON.stringify(node)}}`);
        }
      }
    };

    const root = recurse(filters);

    profiles.push({
      ...rest,
      root,
    });
  };



  return out();
}
