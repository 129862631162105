import { isObject } from 'lodash';
import requestIdleCallback from 'ric-shim';


/**
 * Problem: user-specific data. Same with layouts. 
 *
 * save -> { state, version, timestamp },
 *
 * onLoad = 
 *  hydrate state if exists with version
 *  Catch timestamp into  CacheHelper[sliceName].storageTimestamp
 *
 * onUserLoad =
 *  if CacheHelper[sliceName].storageTimestamp is old, then persist
 *
 * onCacheAction =
 *  if action is cacheable, then persist
 *  Update CacheHelper[sliceName].storageTimestamp
 *
 **/


class LocalStorageMiddlewareHelper {
  /**
   * Helps with determining when to expire a slice in-code
   **/

  constructor() {
    this.slices = new Map();
  }

  hasSlice = (sliceName) => {
    return this.slices.has(sliceName);
  }

  getSlice = (sliceName) => {
    return this.slices.get(sliceName);
  }

  databaseKey = (sliceName) => {
    const { version } = this.slices.get(sliceName);
    return `ts#${sliceName}#${version}`;
  }

  sliceIsExpired = (sliceName, timestamp) => {
    try {
      if (!this.hasSlice(sliceName)) {
        return true;
      }
      const { timestampKey } = this.slices.get(sliceName);
      const lastTimestamp = localStorage.getItem(timestampKey) || 0;
      return timestamp > lastTimestamp;
    } catch (err) {
      console.log(err);
      return true;
    }
  }

  addSlices = (sliceDefinitions) => {
    sliceDefinitions.forEach(({ sliceName, ...rest }) => {
      const def = {
        ...rest,
        stateKey: `ettlsrdx__#${rest.version}#${sliceName}`,
        timestampKey: `ts_ettlsrdx__#${rest.version}#${sliceName}`,
        databaseKey: `ts#${sliceName}#${rest.version}`
      }

      if (!def.matcher || !def.version || !sliceName) {
        throw new Error(`Invalid slice definition ${sliceName}`);
      }

      this.slices.set(sliceName, def);
    });
  }

  isValidAction = (action) => {
    return action?.writeLocalStorage && typeof action.writeLocalStorage === 'number' && !isNaN(action.writeLocalStorage);
  }

  getWriteLocalStorageTime = (action) => {
    if (!this.isValidAction(action)) {
      return;
    }
    return action.writeLocalStorage;
  }

  middleware = ({ getState }) => next => action => {
    const result = next(action);
    const state = getState();

    this.slices.forEach((slice, sliceName) => {
      const { matcher, stateKey, timestampKey, propertiesToRemove } = slice;
      try {
        const timestamp = this.getWriteLocalStorageTime(action);
        if (matcher && matcher(action) && timestamp) {
          requestIdleCallback(() => {
            let data = state?.[sliceName] || null;
            if (propertiesToRemove && propertiesToRemove.length && isObject(data)) {
              data = { ...data };
              propertiesToRemove.forEach(prop => delete data[prop]);
            }
            localStorage.setItem(stateKey, JSON.stringify(data));
            localStorage.setItem(timestampKey, timestamp);
            console.debug('LOCALSTORAGE_MIDDLEWARE:SET', sliceName, timestampKey);
          })
        }
      } catch (err) {
        console.log(err);
      }
    });

    return result;
  }

  hydrator = (state = {}) => {
    this.slices.forEach((slice, sliceName) => {
      const { stateKey } = slice;
      try {
        let item = localStorage.getItem(stateKey);
        if (item) {
          item = JSON.parse(item);
          if (item && item !== null || item !== undefined) {
            console.debug('LOCALSTORAGE_MIDDLEWARE:HYDRATE', sliceName);
            state[sliceName] = item;
          }
        }
      } catch (err) {
        console.log(err);
      }
    });

    return state;
  }
}



export const localStorageMiddlewareHelper = new LocalStorageMiddlewareHelper();





/**
 * @typedef {object} SliceDefinition
 * @property {string} sliceName - The key of the slice in the redux store.
 * @property {function} matcher - A function called with every redux action. Return true
 *                                if the slice's state should be saved to localstorage upon
 *                                completion of this action.
 * @property {string} version - Namespacing inside localstorage.
 * @property {string[]} propertiesToRemove - Properties to remove from the state before saving to localstorage.
 **/

/**
 * Create a localstorage sync with a slice of redux state.
 *
 * @param {SliceDefinition[]} sliceDefinitions
 *
 * @example
 * const sliceDefinitions = [{ sliceName: 'expressions', version: 'v1', matcher: (action) => action?.type?.startsWith('expression/')}]
 * const { localStorageMiddleware, localStorageHydrator} = createLocalStorageMiddleware(sliceDefinitions)
 * return createStore(rootReducer, localStorageHydrator(preloadedState), applyMiddleware(localStorageMiddleware))
 **/
export const createLocalStorageMiddleware = (sliceDefinitions) => {

  localStorageMiddlewareHelper.addSlices(sliceDefinitions);

  return {
    localStorageMiddleware: localStorageMiddlewareHelper.middleware,
    localStorageHydrator: localStorageMiddlewareHelper.hydrator
  };
}
