import _cloneDeep from 'lodash/cloneDeep';
import {
  VALUE_TYPES,
  ARRAY_OPS,
  BOOLEAN_OPS,
  DATE_TYPES,
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import {
  getCurrentTradingDay,
  minutesOfDay,
  parseAssumeMarketTime
} from 'src/utils/datetime/date-fns.tz';
import { format } from 'date-fns';
import { STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { isRollingKey, getRangeDateStrings } from 'src/app/components/pickers/definitions/staticRangeDefinitions';
import { EXPR_PREFIX } from 'src/redux/expressions/globalExpressionReducer.js';
import { expressionListToMap } from '../shared/reducers/expressionReducer.js';


/**  @typedef {import('./mappingDirections/index.js').FormStruct} FormStruct */
/**  @typedef {import('./mappingDirections/index.js').ProfileNodeBinaryArg} ProfileNodeBinaryArg */
/**  @typedef {import('./mappingDirections/index.js').ProfileFilterNode} ProfileFilterNode */
/**  @typedef {import('./mappingDirections/index.js').ProfileGroupNode} ProfileGroupNode */
/**  @typedef {import('./mappingDirections/index.js').QueryNodeBinaryArg} QueryNodeBinaryArg */
/**  @typedef {import('./mappingDirections/index.js').QueryFilterNode} QueryFilterNode */

/**
 * TODO: 11/1/23
 *
 * Our mappingDirection classes assume the overall profiles collection as the base.
 * Because of that, profileToQuery doesn't fit. profileToQuery only applies to a single query.
 *
 * Rework the mappingDirections to use an individual profile as the base, and add the extra properties around it.
 */


/**
 * Individual queries must be mapped to the database format.
 * Not the entire profiles object, just a single one containing a query.
 */


/**
 * Map a query before sending it into the database.
 * Changes the following, in order. THE ORDER IS IMPORTANT.
 *  1) Resolves SLICE_GROUPS into a single filter (SLICE_GROUP[] -> FILTER_NODE{})
 *  2) Resolve 'range' rolling dates into real BETWEEN dates
 *  3) Insert Expression content into the query, from Redux source
 *  4) Replace BETWEEN with two ANDs (col > A AND col <= B)
 *  5) Remove outdated node.left.allowNull. (Now exists under node.allowNull, server handles)
 *  6) Remove extranious Arg keys, like column, value, type. 
 *  7) Remove array from right-hand arguments (flatten, node.right[] -> node.right{})
 *
 * @param {object} profile
 * @param {object} [profile.filters] - Recursive filter section
 * @param {object} [profile.columns] - Flat column list
 * @param {object} [profile.sortArgs] - Column name (or exp name) to sort by
 * @param {string} profile.sortArgs.order - 'asc' or 'desc'
 * @param {string} profile.sortArgs.orderby - Column name to sort by
 * @param {string} day - yyyy-MM-dd
 * @param {string[]} excludedTickers - Component-Level filter to exclude tickers
 * @param {object[]} expressions - Actual expression content from Redux. Match against ID's from profile.
 * @returns {Object} { filters, columns, order } - Mapped to the database format
 */
export default function profileToQuery(
  profile,
  day,
  excludedTickers = [],
  expressions = [],
) {
  const cloned = _cloneDeep(profile);
  let { filters = {}, columns = [], sortArgs } = cloned;

  const expressionMap = expressionListToMap(expressions);

  const out = {};

  if (filters) {
    out.filters = addExplicitArgumentsToFilters({ filters, day, excludedTickers });
    out.filters = modifyFilters({ filters: out.filters, expressionMap });
  }

  if (columns) {
    out.columns = modifyColumns({ columns, expressionMap });
  }

  if (sortArgs) {
    out.order = modifyOrder(sortArgs, expressionMap);
  }

  return out;
}


/**
 * Resolve the orderby argument, and format for query
 * @param {object} sortArgs
 * @param {string} sortArgs.order - 'asc' or 'desc'
 * @param {string} sortArgs.orderby - Column name (or expression) to sort
 * @param {object} expressionMap - Map of expression ID's to their content
 * @returns {object[]} - The formatted order object
 **/
function modifyOrder(sortArgs, expressionMap) {
  const orderItem = {};

  try {
    // only inserts if necissary, otherwise does nothing
    orderItem.order_by = substituteExpression({ column: sortArgs.orderby }, expressionMap);
  } catch (err) {
    // Unknown columns will result in default order being applied serverside
    // Technically, this shouldn't happen. Missing expressions should cause the orderby 
    // to be reset to a clientside default before this point.
    orderItem.order_by = { column: sortArgs.orderby };
  }
  orderItem.order = sortArgs.order.toUpperCase();

  return [orderItem];

}


function addExplicitArgumentsToFilters({ filters, day, excludedTickers }) {
  if (!filters || !Object.keys(filters).length) {
    filters = { AND: [] };
  }

  if (day) {
    try {
      filters.AND.push({
        left: { column: 'day0_date', type: VALUE_TYPES.column },
        operator: BOOLEAN_OPS.EQ,
        right: [{ value: day, type: VALUE_TYPES.value }]
      });
    } catch (err) {
      console.warn('topListFormatQueriesV2() day: Filters.AND doesn\'t exist in top-level', filters);
    }
  }
  if (excludedTickers && excludedTickers.length) {
    try {
      filters.AND.push({
        left: { column: 'ticker', type: VALUE_TYPES.column },
        operator: ARRAY_OPS.NIN,
        right: [{ value: excludedTickers, type: VALUE_TYPES.value }]
      })
    } catch (err) {
      console.warn('topListFormatQueriesV2() excludedTickers: Filters.AND doesn\'t exist in top-level', filters);
    }
  }

  return filters;
}


function modifyColumns({ columns, expressionMap }) {
  if (!columns) return columns;

  return columns.map(col => {
    try {
      return substituteExpression(col, expressionMap);
    } catch (err) {
      console.warn('modifyColumns() error', err);
      return null;
    }
  }).filter(Boolean);
}



/**
 * @param {Object} node
 * @returns {keyof STRUCTURAL_TYPES}
 */
export const decideNodeType = (node) => {
  if (!node || !(typeof node === 'object')) return null;

  if ('operator' in node) return STRUCTURAL_TYPES.FILTER;
  if (STRUCTURAL_TYPES.AND in node) return STRUCTURAL_TYPES.AND;
  if (STRUCTURAL_TYPES.OR in node) return STRUCTURAL_TYPES.OR;
  if (STRUCTURAL_TYPES.SLICE_GROUP in node) return STRUCTURAL_TYPES.SLICE_GROUP;

  return null;
};


/**
 * SQL's BETWEEN isn't valuable for us. Its double-inclusive.
 * Replace a single BETWEEN clause with two OR clauses.
 *
 * The second clause will assume the first one's type
 *
 * We could do this serverside, but in the future we might want a real BETWEEN serverside.
 * @param {ProfileFilterNode} node
 * @param {Array<BOOLEAN_OPS>} operators - Which operators to replace with AND
 * @returns {ProfileGroupNode} - If transformable, return AND group
 * @throws Error - The node is not transformable
 */
export function replaceBetweenWithAnd(node, operators = [BOOLEAN_OPS.GE, BOOLEAN_OPS.LT]) {
  if (!Array.isArray(operators) || !operators.length === 2) {
    throw new Error('replaceBetweenWithAnd() requires an array of two BOOLEAN_OPS, one for each comparison');
  }
  if (!node.operator === BOOLEAN_OPS.BTW || !Array.isArray(node.right)) {
    throw new Error(`replaceBetweenWithAnd() called on non-BTW node, ${JSON.stringify(node)}`);
  }
  const argList = node.right.slice(0, 2);
  if (!argList[0] || !argList[1]) {
    throw new Error(`replaceBetweenWithAnd() called on BTW node with missing arguments. 0:${argList[0]} 1:${argList[1]}`);
  }

  // Extra properties. Right now, just allowNull
  const { left, operator, right, ...rest } = node;

  return {
    AND: [
      {
        ...rest,
        left: { ...node.left },
        operator: operators[0],
        // Using arrays may seem strange, but its for consistency. We remove the arrays later, in flattenRightArgumentArray
        right: [{ ...argList[0] }],
      },
      {
        ...rest,
        left: { ...node.left },
        operator: operators[1],
        right: [{
          ...argList[1],
          // Assume the first one's type
          type: argList[0].type
        }]
      }
    ]
  }
}


export function resolveRollingDateRange(node) {
  if (!decideNodeType(node) === STRUCTURAL_TYPES.FILTER) {
    throw new Error('resolveRollingDateRange() called on non-filter node');
  }
  if (!node?.dateType === DATE_TYPES.ROLLING) {
    throw new Error('resolveRollingDateRange() called on non-rolling date');
  }
  let rollingKey = node.right[0]?.value;
  if (!isRollingKey(rollingKey)) {
    throw new Error(`resolveRollingDateRange() called on unknown rolling_key value. right[0].value: ${node?.right[0]?.value}, node: ${node}`);
  }
  // TODO: What about different formats? We'd have to pull in colDefs, but thats not a fixed value.
  // We could pass in colDefs on mapping functions? That probably makes the most sense. Ignore for now.

  // Rolling dates always reference TODAY as end date, for now. Ignore BTW, ignore endDate.
  const { startDate } = getRangeDateStrings(rollingKey, 'yyyy-MM-dd');
  const { dateType, ...leftRest } = node.left;
  return {
    ...node,
    left: {
      ...leftRest,
    },
    right: [{
      type: VALUE_TYPES.value,
      column: null,
      value: startDate,
    }]
  }
}


/**
  * Transform the full Arg to the shortened Query version
  * @param {ProfileNodeBinaryArg} side
  * @returns {QueryNodeBinaryArg}
  */
const resolveArg = (side) => {
  if (!side) return {};

  if (side.type === VALUE_TYPES.column) {
    const { type, value, ...rest } = side;
    return rest;
  }
  if (side.type === VALUE_TYPES.value) {
    const { type, column, expression, ...rest } = side;
    return rest;
  }
  console.warn('Arg type not recognized', JSON.stringify(side));
  return side;
};


/**
 * FILTER entities right-hand arguments look like this client-side:
 *      { column: 'vol', value: 200, type: VALUE_TYPES.value };
 *    Resolve them to a single property, based on 'type':
 *      { value: 200 }
 * @param {ProfileFilterNode} node
 * @returns {QueryFilterNode}
 */
export function resolveNodeBinaryArguments(node) {
  let left = resolveArg(node.left);
  let right;
  if (Array.isArray(node.right)) {
    right = node.right.map(resolveArg);
  } else {
    right = resolveArg(node.right);
  }

  return { ...node, left, right };
}


/**
 * Insert the expression content into the node, if applicable.
 * FROM:
 * { right: { column: 'expr_0' } }
 * TO:
 * { right: {
 *     expression: 'A + B',
 *     args: {A: ..., B: ...},
 *     label: 'My Expression'
 *  } }
 * @param {QueryFilterNode} node
 * @param {Object<str, Object>} expressionMap - Map of expression ID's to their content
 * @returns {QueryFilterNode}
 *
 **/
function insertExpression(node, expressionMap) {
  if (!decideNodeType(node) === STRUCTURAL_TYPES.FILTER) {
    console.warn('insertExpression only accepts FILTER nodes, ignoring');
    return node;
  }

  let left = substituteExpression(node.left, expressionMap);
  let right;
  if (Array.isArray(node.right)) {
    right = node.right.map(node => substituteExpression(node, expressionMap));
  } else {
    right = substituteExpression(node.right, expressionMap);
  }

  return { ...node, left, right };
}


export function substituteExpression(node, expressionMap) {
  if (!node.column || !(typeof node.column === 'string')) return node;
  if (!node.column.startsWith(EXPR_PREFIX)) return node;

  const exprObject = expressionMap[node.column];

  if (!exprObject) {
    throw Error(`Expression ${node.column} not found. Removing node.`);
  }
  if (exprObject?.invalid) {
    throw Error(`Expression ${node.column} is invalid. Removing node.`);
  }

  const { column, ...rest } = node;
  return {
    ...rest,
    expression: exprObject?.expression,
    args: exprObject?.args,
    label: exprObject?.name
  }
}



/**
 * Right arguments are arrays. We need them to be flat. pick the first one.
 * Make sure you map BETWEEN to AND before this step, or you will lose data.
 *  node.right = [binaryArg] => node.right = binaryArg
 *  @param {QueryFilterNode} node
 *  @returns {QueryFilterNode}
 */
export function flattenRightArgumentArray(node) {
  if (!decideNodeType(node) === STRUCTURAL_TYPES.FILTER) {
    console.warn('flattenRightArgumentArray only accepts FILTER nodes, ignoring');
    return node;
  }
  if (node.operator === BOOLEAN_OPS.BTW) {
    console.warn('Found BTW query operator. This probably should have been replaced with AND. Ignoring.', node);
    return node;
  }
  if (!Array.isArray(node.right)) {
    return node;
  }
  return { ...node, right: node.right[0] }
}


/**
 * Take the current time, and pick the single SLICE that matches.
 * Remove all other slices. It becomes a single filter node.
 * @param {ProfileGroupNode} node - Slice Group node
 * @param {Date} now
 * @return {ProfileFilterNode} - Filer node that it contains, no slice group
 */
export function resolveSliceGroup(node, now) {
  let selectedNode = node.SLICE_GROUP[0];

  for (let i = 0; i < node.SLICE_GROUP.length; i++) {
    const sliceNode = node.SLICE_GROUP[i];
    const nextSliceNode = node.SLICE_GROUP[i + 1];

    if (nextSliceNode === undefined) {
      // last node, this has to be it
      // TODO: what about 00->04am?
      selectedNode = sliceNode;
      break;
    }

    const nowMinutes = minutesOfDay(now);
    const startTimeMinutes = minutesOfDay(parseAssumeMarketTime(sliceNode.startTime, 'HH:mm'));
    const nextStartTimeMinutes = minutesOfDay(parseAssumeMarketTime(nextSliceNode.startTime, 'HH:mm'));

    if (nowMinutes >= startTimeMinutes && nowMinutes < nextStartTimeMinutes) {
      selectedNode = sliceNode;
      break;
    }
  }

  /* eslint-disable-next-line no-unused-vars */
  const { startTime, ...newNode } = selectedNode;
  return newNode;
}



/**
 * Recurse the tree, and apply all modifications
 * @param {Object} args
 * @param {Object} args.filters - The root of the filters tree
 * @param {Date} args.now - localized
 * @param {Object<str, Object>} args.expressionMap - Map of expression ID's to their content
 */
function modifyFilters({
  filters,
  now = getCurrentTradingDay(),
  expressionMap = {}
}) {
  if (!filters) return filters;

  const recurse = (node) => {
    const type = decideNodeType(node);

    switch (type) {
      case STRUCTURAL_TYPES.AND:
      case STRUCTURAL_TYPES.OR: {
        const nodes = node[type].map(recurse);
        // If expression is missing, we signify it. Remove those nodes.
        return { [type]: nodes.filter(n => !n.removeNode) }
      }
      case STRUCTURAL_TYPES.SLICE_GROUP: {
        const newNode = resolveSliceGroup(node, now);
        return recurse(newNode);
      }
      case STRUCTURAL_TYPES.FILTER: {
        let newNode = node;

        if (newNode?.dateType === DATE_TYPES.ROLLING) {
          try {
            newNode = resolveRollingDateRange(newNode);
            // No new group created, continue
          } catch (err) {
            console.warn(err)
          }
        }

        if (newNode.operator === BOOLEAN_OPS.BTW) {
          try {
            newNode = replaceBetweenWithAnd(newNode);
            // the node is now an AND group. Keep recursing.
            return recurse(newNode)
          } catch (err) {
            console.warn(err)
          }
        }


        if (newNode?.left?.allowNull) {
          try {
            // node.left.allowNull is no longer valid.
            // We use node.allowNull, and handle it serverside
            const { allowNull, ...rest } = newNode.left;
            newNode = {
              ...newNode,
              left: rest
            }
          } catch (err) {
            console.warn(err)
          }
        }

        newNode = resolveNodeBinaryArguments(newNode);

        try {
          newNode = insertExpression(newNode, expressionMap);
        } catch (err) {
          console.debug(err)
          return { ...newNode, removeNode: 'Missing expression' }; // signifier to skip this element.
        }

        return flattenRightArgumentArray(newNode);
      }
      default: {
        console.warn(JSON.stringify(filters));
        throw new Error(`Unknown node type "${type}", node ${JSON.stringify(node)}}. Check logs.`);
      }
    }
  };

  return recurse(filters);
}
