import profileToForm from './profileToForm';
import formToProfile from './formToProfile'

/**
 * @typedef {Object} ProfileStruct
 * @property {string} id - The ID of the profile
 * @property {string} name - The name of the profile
 * @property {boolean} [predefined] - Optional, if the profile is predefined (uneditable)
 * @property {Object} filters - recursive query tree
 */

/**
 * @typedef {Object} FormStruct
 * @property {string} id - The ID of the profile
 * @property {string} name - The name of the profile
 * @property {boolean} [predefined] - Optional, if the profile is predefined (uneditable)
 * @property {Leaf} root - The root node of the filter tree
 */

/**
 * @typedef {Object} ProfileNodeBinaryArg
 * @property {'value'|'column'} type - The currently selected type
 * @property {string|number} [value]
 * @property {string} [column]
 */

/**
 * The Node inside a ProfileStruct
 * @typedef {Object} ProfileFilterNode
 * @property {ProfileNodeBinaryArg} left
 * @property {string} operator
 * @property {ProfileNodeBinaryArg[]} right
 * @property {boolean} [allowNull] - If the clause should evaluate true if any dependancy is NULL
 */

/**
 * @typedef {Object} ProfileGroupNode
 * @property {Object[]} [AND]
 * @property {Object[]} [OR]
 * @property {Object[]} [SLICE_GROUP]
 */

/**
 * @typedef {Object} QueryNodeBinaryArg
 * @property {string|number} [value]
 * @property {string} [column]
 * @property {string} [expression]
 * @property {Object} [args] - Expression args
 * @property {string} [label] - Expression label
 * @property {}
 */

/**
 * @typedef {Object} QueryFilterNode
 * @property {QueryNodeBinaryArg} left
 * @property {string} operator
 * @property {QueryNodeBinaryArg|QueryNodeBinaryArg[]} right
 * @property {boolean} [allowNull] - If the clause should evaluate true if any dependancy is NULL
 */



export const FORM = 'FORM';
export const PROFILE = 'PROFILE';
export const QUERY = 'QUERY';


/**
 * Decides what kinds of profile mappings are allowed.
 * @readonly
 */
export const PROFILE_STRUCTURES = {
  /**
   * The base profile saved and loaded from dynamo
   * denormalized
   * extranious properties: INCLUDED
   */
  [PROFILE]: {
    name: [PROFILE],
    mapsTo: {
      [FORM]: profileToForm,
      // [QUERY]: profileToQuery
    },
  },
  /**
   * The entity profile, used in useReducer forms. What is directly written to via user input
   * normalized
   * extranious properties: INCLUDED
   */
  [FORM]: {
    name: [FORM],
    mapsTo: {
      [PROFILE]: formToProfile
    }
  },
  /**
   * The query profile, sent to RDS for querying
   * denormalized
   * extranious properties: NOT INCLUDED
   */
  // [QUERY]: {
  //   name: [QUERY],
  //   mapsTo: {}
  // }
};



/**
 * Enum for types of nodes
 * @readonly
 * @enum {string}
 */
export const STRUCTURAL_TYPES = {
  AND: 'AND',
  OR: 'OR',
  SLICE_GROUP: 'SLICE_GROUP',
  FILTER: 'FILTER',
};


/**
 * Enum for the position of a child node relative to its parent group
 * Position excludes children that are Groups themselves.
 * @readonly
 * @enum {string}
 */
export const NODE_POSITIONS = {
  FIRST: 'FIRST',
  LAST: 'LAST',
  MIDDLE: 'MIDDLE'
};



/**
 * Checks that "data" has the "struct" structure
 * @throws Error
 * @param {Object} data
 * @param {Object} struct
 * @returns {boolean}
 */
const validateStructure = (data, struct) => {
  return true;
};


/**
 *
 * @param {Object} data
 * @example mapStructure(data).from('PROFILE').to('FORM')
 */
export const mapProfileStructure = (data) => {
  if (!data) throw new Error('No data provided');
  return {
    /** @param {keyof PROFILE_STRUCTURES} fromStruct */
    from: (fromStruct) => {
      if (!fromStruct || !(fromStruct in PROFILE_STRUCTURES)) {
        throw new Error(`Incorrect fromStruct provided "${fromStruct}"`);
      }

      validateStructure(data, fromStruct);

      return {
        /**
         * @param {keyof PROFILE_STRUCTURES} toStruct
         * @returns {Object} - The mapped data
         */
        to: (toStruct) => {
          if (!toStruct || !(toStruct in PROFILE_STRUCTURES)) {
            throw new Error(`Incorrect fromStruct provided "${fromStruct}"`);
          }
          const from = PROFILE_STRUCTURES[fromStruct];
          const to = PROFILE_STRUCTURES[toStruct];

          if (!(to.name in from.mapsTo)) {
            throw new Error(`fromStruct "${from.name}" not a valid mapping toStruct "${to.name}". valid mappings: ${from.mapsTo}`);
          }

          return from.mapsTo[to.name](data);
        }
      };
    }
  };
};
