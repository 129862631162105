import produce from 'immer';
import { PROFILE_CONFIG, createDefaultLayoutTemplate } from './topListLayoutSchema';
import {
  CREATE_COMPONENT,
  CREATE_COMPONENT_ZERO_STATE,
  CREATE_LAYOUT,
  COPY_LAYOUT,
  RENAME_LAYOUT,
  DELETE_LAYOUT,
  UPDATE_PROFILE,
  UPDATE_LINKED_DATA,
  UPDATE_COMPONENT,
  REORDER_LAYOUT_TABS,
  SHOW_ADD_COMPONENT_OVERLAY,
  HIDE_ADD_COMPONENT_OVERLAY,
  UPDATE_ACTIVE_LAYOUT,
  UPDATE_CURRENT_NODE,
  UPDATE_COMPONENT_LINK_COLOR,
  UPDATE_TICKER_SEARCH_LINK_COLOR,
  BEGIN__LOAD_STORAGE,
  SUCCESS__LOAD_STORAGE,
  FAILURE__LOAD_STORAGE,
  INITIALIZE_DEFAULT_LAYOUT,
  ADD_LAYOUT_TAB,
  REMOVE_LAYOUT_TAB,
  REQUEST__FETCH_LIST_WATCHLIST_PROFILES,
  INITIALIZE__FETCH_LIST_WATCHLIST_PROFILES,
  FETCH_LIST_WATCHLIST_PROFILES,
  FETCH_WATCHLISTS,
  REQUEST__FETCH_WATCHLISTS,
  REQUEST__ADD_WATCHLIST_TICKER,
  ADD_WATCHLIST_TICKER,
  REORDER_WATCHLIST_TICKERS,
  REQUEST__REMOVE_WATCHLIST_TICKER,
  REMOVE_WATCHLIST_TICKER,
  RENAME_WATCHLIST,
  CREATE_WATCHLIST
} from './topListLayoutActions';


const {
  layout: defaultLayout,
  layoutId: defaultLayoutId
} = createDefaultLayoutTemplate();


const initialState = {
  activeLayout: defaultLayoutId,
  links: {},
  layoutTabs: [defaultLayoutId],
  layouts: {
    [defaultLayoutId]: defaultLayout
  },
  [PROFILE_CONFIG.SCANNER_FILTERS.listKey]: PROFILE_CONFIG.SCANNER_FILTERS.predefinedProfiles,
  [PROFILE_CONFIG.SCANNER_COLUMNS.listKey]: PROFILE_CONFIG.SCANNER_COLUMNS.predefinedProfiles,
  [PROFILE_CONFIG.KEY_STATS.listKey]: PROFILE_CONFIG.KEY_STATS.predefinedProfiles,
  [PROFILE_CONFIG.HISTORY_FILTERS.listKey]: PROFILE_CONFIG.HISTORY_FILTERS.predefinedProfiles,
  [PROFILE_CONFIG.HISTORY_COLUMNS.listKey]: PROFILE_CONFIG.HISTORY_COLUMNS.predefinedProfiles,
  [PROFILE_CONFIG.NEWS_COLUMNS.listKey]: PROFILE_CONFIG.NEWS_COLUMNS.predefinedProfiles,
  // Watchlist is handleded differently. Its default profiles are stored in rds, and fetched independantly of the layouts.
  [PROFILE_CONFIG.WATCHLIST_ROWS.listKey]: [],
  // TODO: TEMP_EXPRESSION
  // [PROFILE_CONFIG.EXPRESSIONS.listKey]: PROFILE_CONFIG.EXPRESSIONS.predefinedProfiles,
  tickerSearchLinkColor: 'white',
  ui: {
    showComponentSelectOverlay: false,
  },
  isFetching: {
    layouts: true,
    watchlists: true,
    watchlistData: true,
  },
  initialized: {
    watchlists: false
  },
  [PROFILE_CONFIG.WATCHLIST_ROWS.dataKey]: [], // Combines all active watchlist tickers and columns into one array
  incrementer: 0
};


// Lord forgive me for what's below...

const topListLayoutReducer = (state = initialState, action) => {
  switch (action.type) {

    case CREATE_COMPONENT: {
      return action.payload;
    }

    case CREATE_COMPONENT_ZERO_STATE: {
      return action.payload;
    }

    case UPDATE_CURRENT_NODE: {
      return action.payload;
    }

    case CREATE_LAYOUT: {
      return action.payload;
    }

    case COPY_LAYOUT: {
      return action.payload;
    }

    case DELETE_LAYOUT: {
      return action.payload;
    }

    case RENAME_LAYOUT: {
      return action.payload;
    }

    case UPDATE_ACTIVE_LAYOUT: {
      return produce(state, draft => {
        draft.activeLayout = action.payload;
        draft.incrementer++;
      });
    }

    case ADD_LAYOUT_TAB: {
      return action.payload;
    }

    case REMOVE_LAYOUT_TAB: {
      return action.payload;
    }

    case REORDER_LAYOUT_TABS: {
      const layoutOrderIds = action.payload;
      return produce(state, draft => {
        draft.layoutTabs = layoutOrderIds;
        draft.incrementer++;
      });
    }

    case UPDATE_COMPONENT: {
      return action.payload;
    }

    case UPDATE_COMPONENT_LINK_COLOR: {
      return action.payload;
    }

    case UPDATE_TICKER_SEARCH_LINK_COLOR: {
      return action.payload;
    }

    case UPDATE_LINKED_DATA: {
      return action.payload;
    }

    case UPDATE_PROFILE: {
      return action.payload;
    }

    case SHOW_ADD_COMPONENT_OVERLAY: {
      return produce(state, draft => {
        draft.ui.showComponentSelectOverlay = true;
      });
    }

    case HIDE_ADD_COMPONENT_OVERLAY: {
      return produce(state, draft => {
        draft.ui.showComponentSelectOverlay = false;
      });
    }

    case BEGIN__LOAD_STORAGE: {
      return produce(state, draft => {
        draft.isFetching.layouts = true;
      });
    }

    case SUCCESS__LOAD_STORAGE: {
      return produce(state, () => {
        return {
          ...state,
          ...action.payload,
          isFetching: {
            ...state.isFetching,
            layouts: false,
          },
          incrementer: 0
        };
      });
    }

    case FAILURE__LOAD_STORAGE: {
      return produce(state, draft => {
        draft.isFetching.layouts = false;
      });
    }

    case INITIALIZE_DEFAULT_LAYOUT: {
      return produce(state, draft => {
        draft.isFetching.layouts = false;
      });
    }

    case FETCH_LIST_WATCHLIST_PROFILES: {
      return produce(state, draft => {
        draft.isFetching.watchlists = false;
        draft[PROFILE_CONFIG.WATCHLIST_ROWS.listKey] = action.payload;
      });
    }

    case FETCH_WATCHLISTS: {
      return produce(state, draft => {
        draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey] = action.payload;
        draft.isFetching.watchlistData = false;
      });
    }

    case ADD_WATCHLIST_TICKER: {
      const { tickerData, loadingItemId } = action.payload;
      return produce(state, draft => {
        draft.isFetching.watchlistData = false;
        // remove loading row
        const idx = draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey].findIndex(row => row.item_id === loadingItemId);
        if (idx !== -1) {
          draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey].splice(idx, 1);
        }

        // add new data (if exists)
        tickerData.forEach(t => {
          let dataIdx = draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey].findIndex(row => row.item_id === t.item_id);
          if (dataIdx === -1) {
            draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey].push(t);
          } else {
            draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey][dataIdx] = {
              ...draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey][dataIdx],
              ...t
            };
          }
        });
      });
    }

    case REORDER_WATCHLIST_TICKERS: {
      const { watchlistProfileId, order } = action.payload;
      return produce(state, draft => {
        const profileIdx = draft[PROFILE_CONFIG.WATCHLIST_ROWS.listKey].findIndex(p => p.id === watchlistProfileId);
        if (profileIdx !== -1) {
          // TODO: We don't use this ticker array on profile anymore. Remove?
        }

        draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey].forEach((row, idx) => {
          if (row.watchlist_id === watchlistProfileId) {
            const newOrderIdx = order.indexOf(row.item_id);
            if (newOrderIdx !== -1) {
              draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey][idx].row_order = newOrderIdx;
            }
          }
        });
      });
    }

    case RENAME_WATCHLIST: {
      const { watchlistProfileId, newName } = action.payload;
      return produce(state, draft => {
        const profileIdx = draft[PROFILE_CONFIG.WATCHLIST_ROWS.listKey].findIndex(p => p.id === watchlistProfileId);
        if (profileIdx !== -1) {
          draft[PROFILE_CONFIG.WATCHLIST_ROWS.listKey][profileIdx].profileName = newName;
        }
      });
    }

    case REMOVE_WATCHLIST_TICKER: {
      // const { watchlistProfileId, ticker, status } = action.payload;
      return produce(state, draft => {
        draft.isFetching.watchlistData = false;
      });
    }

    case CREATE_WATCHLIST: {
      const { profile, data } = action.payload;
      return produce(state, draft => {
        if (profile) {
          draft[PROFILE_CONFIG.WATCHLIST_ROWS.listKey].push(profile);
        }
        if (data) {
          draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey].push(...data);
        }
      });
    }

    case INITIALIZE__FETCH_LIST_WATCHLIST_PROFILES: {
      return produce(state, draft => {
        draft.initialized.watchlists = true;
      });
    }

    case REQUEST__FETCH_LIST_WATCHLIST_PROFILES: {
      return produce(state, draft => {
        draft.isFetching.watchlists = true;
      });
    }

    case REQUEST__ADD_WATCHLIST_TICKER: {
      const { watchlistProfileId, ticker, loadingItemId } = action.payload;
      return produce(state, draft => {
        draft.isFetching.watchlistData = true;
        draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey].push({
          watchlist_id: watchlistProfileId,
          item_id: loadingItemId,
          loadingRow: true,
          ticker: ticker,
          row_order: 999, // any large number will do, we just want it last.
        });
      });
    }

    case REQUEST__FETCH_WATCHLISTS: {
      return produce(state, draft => {
        draft.isFetching.watchlistData = true;
      });
    }

    case REQUEST__REMOVE_WATCHLIST_TICKER: {
      const { watchlistProfileId, watchlistItemId } = action.payload;
      return produce(state, draft => {
        draft.isFetching.watchlistData = true;

        const idx = draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey]
          .findIndex(row => row.watchlist_id === watchlistProfileId && row.item_id === watchlistItemId);

        if (idx !== -1) {
          draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey].splice(idx, 1);
        }
      });
    }


    default:
      return state;
  }
};


export default topListLayoutReducer;

