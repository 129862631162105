import _cloneDeep from 'lodash/cloneDeep';
import {
  ARRAY_OPS,
  BOOLEAN_OPS,
  BOOLEAN_OP_LABELS,
  ROLLING_DATE_OP_LABELS,
  DATE_TYPES,
  ARRAY_OP_LABELS,
  INPUTS,
  DATE_TYPE_LABELS,
  getAdornmentChars
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import {
  getCurrentTradingDay,
  minutesOfDay,
  parseAssumeMarketTime
} from 'src/utils/datetime/date-fns.tz';
import { format } from 'date-fns';
import { STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { isRollingKey, getRangeDateStrings, getRollingDateFromKey } from 'src/app/components/pickers/definitions/staticRangeDefinitions';
import { decideNodeType, resolveSliceGroup } from './profileToQuery';
import { MarketOpenIcon } from 'src/theme/EdgeIcons';
import { adorn, abbreviate } from 'src/utils/abbreviateNumber';
import { profileExpressionToPreview } from '../ExpressionForm/expressionUtils/expressionMappings';
import { EXPR_PREFIX } from 'src/redux/expressions/globalExpressionReducer';


/**
 * @typedef {Object} PreviewNodeArg
 * @property {string} text
 * @property icon
 * @property {Object} style
 **/

/**
 * @typedef {Object} PreviewNode
 * @property {PreviewNodeArg} left
 * @property {PreviewNodeArg} right
 **/

/**  @typedef {import('./mappingDirections/index.js').FormStruct} FormStruct */
/**  @typedef {import('./mappingDirections/index.js').ProfileNodeBinaryArg} ProfileNodeBinaryArg */
/**  @typedef {import('./mappingDirections/index.js').ProfileFilterNode} ProfileFilterNode */
/**  @typedef {import('./mappingDirections/index.js').ProfileGroupNode} ProfileGroupNode */
/**  @typedef {import('./mappingDirections/index.js').QueryNodeBinaryArg} QueryNodeBinaryArg */
/**  @typedef {import('./mappingDirections/index.js').QueryFilterNode} QueryFilterNode */

/**
 * We sometimes want to show the user a popup with a preview of their
 * current active filters. Build the data structure here.
 * Very similar to profileToQuery, except each node only contains a 
 * string to render, not extra options.
 *
 * You should:
 * Resolve slice groups
 * Insert rolling dates
 * Format betweens
 *
 * STRUCTURE:
 * {
 *  AND: [
 *    {
 *      left: { text: 'ddd', icon: 'time' },
 *      right: { text: '>= 1'}
 *    },
 *    {
 *      OR: [
 *        { left: {}, right: {}},
 *        { left {}, right: {}}
 *      ]
 *    }
 *  ]
 * }
 **/


const ALL_OP_LABELS = {
  ...BOOLEAN_OP_LABELS,
  ...ARRAY_OP_LABELS
}


export default function mapSingleProfileStructToPreviewStruct({
  filters,
  columnDefs,
  now = getCurrentTradingDay(),
  maxYears,
  defaultDateConfig,
  expressions = []
}) {
  filters = _cloneDeep(filters);

  if (!columnDefs || !columnDefs.length) {
    throw Error('No columnDefs provided');
  }
  if (!now) {
    throw Error('No now provided');
  }
  if (!maxYears) {
    throw Error('No maxYears provided');
  }

  const expressionMap = expressions.reduce((acc, exp) => {
    acc[exp.id] = exp;
    return acc;
  }, {})

  return modify({ filters, columnDefs, now, maxYears, defaultDateConfig, expressionMap });
}


const resolveArg = (arg, columnDefs, leftColDef, expressionMap = {}) => {
  if (arg?.column?.startsWith?.(EXPR_PREFIX)) {
    const expressionObj = expressionMap[arg.column];
    if (!expressionObj) return null;

    try {
      profileExpressionToPreview(expressionObj, columnDefs);
    } catch (err) {
      return expressionObj?.label || 'Unknown Expression'
    }
  } else if (arg?.column) {
    const colDef = columnDefs.find(d => d.name === arg.column);
    return colDef?.label || arg.column;
  }

  if (leftColDef?.input === INPUTS.MULTI_SELECT) {
    const labels = arg.value.map(v => {
      return leftColDef?.options?.find(o => o.name === v)?.label || v;
    });
    if (labels.length > 1) {
      return `${labels[0]} +${labels.length - 1}`;
    }
    return labels[0];
  }
  if (leftColDef?.input === INPUTS.COMPARE) {
    const { startAdornment, endAdornment } = getAdornmentChars(leftColDef);
    if (arg.value === null) return 'None';
    return adorn(abbreviate(arg.value), startAdornment, endAdornment);
  }

  return arg.value;
};



function modify({
  filters,
  columnDefs,
  now,
  defaultDateConfig = {},
  maxYears,
  expressionMap = {}
}) {

  const recurse = (node, args = {}) => {
    const type = decideNodeType(node);

    switch (type) {
      case STRUCTURAL_TYPES.AND:
      case STRUCTURAL_TYPES.OR: {
        const nodes = node[type].map(recurse);
        return { [type]: nodes.filter(Boolean) }
      }
      case STRUCTURAL_TYPES.SLICE_GROUP: {
        const resolvedNode = resolveSliceGroup(node, now)
        return recurse(resolvedNode, { icon: MarketOpenIcon })
      }
      case STRUCTURAL_TYPES.FILTER: {
        // Return null to hide this clause entirely. Like when expression has been deleted.

        let newNode = { left: { ...args }, right: {} };

        const leftColDef = columnDefs.find(col => col.name === node?.left?.column)
        let label;

        if (!leftColDef) {
          console.warn(`Column not found: ${node?.left?.column}`);
          if (node?.left?.column.startsWith(EXPR_PREFIX)) {
            return null;
          }
          label = node?.left?.column || 'Unknown';
        } else {
          label = leftColDef.label;
        }

        newNode.left.text = label;

        let operator = node?.operator;
        let dateType = node?.dateType;
        let valueLabel = '';

        if (dateType === DATE_TYPES.ROLLING) {
          const dateConfig = {
            ...defaultDateConfig,
            ...(leftColDef?.dateConfig || {}),
          }
          const value = node?.right?.[0]?.value;
          const rollingDate = dateConfig?.rollingDates.find(d => d.key === value);
          valueLabel = rollingDate?.label || value;
          newNode.right.text = `${valueLabel}`;

          // const adjusted = changeDateMessageViaRestriction(value, maxYears, now);

        } else if (operator === BOOLEAN_OPS.BTW) {
          let first = resolveArg(node?.right?.[0], columnDefs, leftColDef, expressionMap);
          let second = resolveArg(node?.right?.[1], columnDefs, leftColDef, expressionMap);
          if (first === null || second === null) return null;
          newNode.right.text = `BTW ${first} & ${second}`;
        } else {
          let first = resolveArg(node?.right?.[0], columnDefs, leftColDef, expressionMap);
          if (first === null) return null;
          newNode.right.text = `${ALL_OP_LABELS[operator]} ${first}`;
        }
        return newNode;
      }
      default: {
        throw Error(`Unknown node type: ${type}, ${node}`);
      }
    }
  }

  return recurse(filters);
}
