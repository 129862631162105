import React from 'react';
import clsx from 'clsx';
import { useFormDispatch } from 'src/app/slicedForm/shared/context/FormProvider';
import { getFilterEntityDefaults } from 'src/app/slicedForm/shared/schema/schemaBuilder';
import {
  CREATE_SLICE,
  CREATE_SLICE_GROUP,
} from 'src/app/slicedForm/FilterForm/reducers/filterReducer';
import useInputStyles, { ButtonStyleProps } from 'src/app/slicedForm/shared/styles/useInputStyles';
import useEntitySlices from 'src/app/slicedForm/FilterForm/hooks/useEntitySlices';
import { useFormEntityContext, useFormGroupContext } from '../context/FormEntityContext';
import { STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { ALLOW_NULL } from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import TimeSliceInput from 'src/app/slicedForm/FilterForm/elements/TimeSliceInput';
import { Switch, Case } from 'src/app/components/utility/SwitchCase';
import EntityIconButton from 'src/app/slicedForm/FilterForm/elements/EntityIconButton';
import SmallFilterWindow from 'src/app/components/filterContainers/SmallFilterWindow';
import Popover from 'src/app/slicedForm/FilterForm/elements/Popover';
import ColumnPickerForm, { useColumnPickerFormWidth } from 'src/app/slicedForm/FilterForm/elements/ColumnPickerForm';
import ColumnIconSelector from '../../shared/elements/ColumnIconSelector';
import { MarketOpenIcon } from 'src/theme/EdgeIcons';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { useEntity } from 'src/app/slicedForm/FilterForm/context/useFormEntity';
import { useColumnDef } from 'src/app/slicedForm/shared/context/ColumnDefsProvider';
import {
  Box,
  Button,
  Checkbox,
  Typography,
  FormGroup,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  disabled: {
    opacity: .6,
    pointerEvents: 'none'
  },
  left: {
    flex: 1
  },
  colDefbutton: {
    fontWeight: 500
  },
  childSliceIconCont: {
    display: 'flex',
    paddingLeft: 8,
    alignSelf: 'flex-start'
  },
  checkbox: {
    borderRadius: 4,
    padding: 4,
    marginLeft: 5,
    marginRight: 5,
    '&:hover': {
      backgroundColor: 'unset !important'
    }
  },
  checkboxSubLabel: {
    paddingLeft: 30,
    fontSize: 13,
    color: theme.palette.text.secondary
  },
  formLabel: {
    '&:hover .MuiCheckbox-colorSecondary': {
      // backgroundColor: theme.palette.action.hover,
      backgroundColor: 'rgba(138, 133, 255, 0.08)',
      // borderRadius: 4
    }
  },
  bottomSliceButton: {
    // pretend its in another row
    paddingTop: 4,
    marginBottom: -3,
  },
  childSliceIcon: {
    position: 'relative',
    borderBottomLeftRadius: 5,
    height: 15,
    width: 32,
    marginLeft: 16,
    color: theme.palette.text.primary,
    borderLeft: `2px solid currentColor`,
    borderBottom: `2px solid currentColor`,
    opacity: .35,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 6,
      height: 6,
      right: 0,
      bottom: 0,
      transform: 'translate(1px,5px) rotate(45deg)',
      borderRight: `2px solid currentColor`,
      borderTop: `2px solid currentColor`,
    }
  },
  settingsPopup: {
    '& .MuiIconButton-root': {
      padding: 6,
      borderRadius: theme.grid.borderRadius,
      color: theme.palette.text.primary
    },
    '& .ett-panel-icon-button': {
      '& .MuiIconButton-label': {
        minWidth: 22.28,
        minHeight: 22.28
      },
      '& .MuiSvgIcon-root': {
        fontSize: 20
      }
    }
  },
  panel: {
    maxWidth: 530
  },
}));


function SliceIconButton({
  text,
  onClick,
  disabled
}) {
  return (
    <EntityIconButton
      aria-label="Add Time Slice (Modify filters based on time of day)"
      title="Add Time Slice (Modify filters based on time of day)"
      disableRipple
      text={text}
      disabled={disabled}
      onClick={onClick}
    >
      {text && (<span style={{ marginRight: 2, lineHeight: 1, paddingTop: 1, fontWeight: 400 }}>Slice</span>)}
      <MarketOpenIcon />
    </EntityIconButton>
  )
}


/**
 * Helper function to send an update to entity['left.column'];
 */
function useHandleUpdateColumn(oldColDef, inputProps, popoverApi) {
  return (newColDef) => {
    popoverApi?.handleClose?.();

    const defaults = getFilterEntityDefaults(newColDef);
    if (!defaults || !newColDef) {
      console.warn(`No defaults/newColDef found for column ${newColDef}}`);
      return;
    }

    const columnIsSimilarType = newColDef?.columnType && newColDef?.columnType === oldColDef?.columnType;

    let options = {
      colDef: newColDef,
      columnDefaults: columnIsSimilarType ? null : defaults
    }

    inputProps?.onChange?.(newColDef?.name, options);
  }
}




function ExtraOptionsPopover() {
  const classes = useStyles();
  const { id, disabled } = useFormEntityContext();
  const { useWatch, useRegister } = useEntity(id);
  // const allowNullInputProps = useRegister('left.allowNull', { validator: 'none' });
  const allowNullInputProps = useRegister('allowNull', { validator: 'none' });
  const column = useWatch('left.column');
  const colDef = useColumnDef(column);


  return (
    <SmallFilterWindow
      className={classes.settingsPopup}
      popoverTitle="Additional Settings"
      iconText="Additional Settings"
      shouldHideIconText
      Icon={SettingsIcon}
      disabled={disabled || !column || !colDef?.name}
    >
      <div className={classes.panel}>
        <FormGroup>
          {colDef?.allowNull === ALLOW_NULL.USER_CONTROLLED && (
            <>
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Checkbox
                    checked={Boolean(allowNullInputProps?.value)}
                    className={classes.checkbox}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      allowNullInputProps?.onChange(checked, { removeFalsy: !checked });
                    }}
                    disableRipple
                    name="allowNull"
                  />
                }
                label="Include rows with missing values"
              />
              <Typography variant="body2" className={classes.checkboxSubLabel}>
                Include rows where the metric value matches your filters, or is missing. Useful for some Share Structure metrics which may lack data.
              </Typography>
            </>
          )}
        </FormGroup>
      </div>
    </SmallFilterWindow>
  );
}


/**
 * <SwtichCase/> wrapper for Header, either Regular or Slice version
 */
function FormEntityHeader({ className }) {
  const { groupType } = useFormGroupContext();

  return (
    <Switch expr={groupType === STRUCTURAL_TYPES.SLICE_GROUP}>
      <Case when={true}>
        <TimeSliceHeader className={className} />
      </Case>
      <Case default>
        <RegularHeader className={className} />
      </Case>
    </Switch>
  );
}


export default FormEntityHeader;



/**
 * Simply shows the Column Name. Clicking it will open Column Select Dropdown.
 * @component
 */
function RegularHeader({ className }) {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const dispatch = useFormDispatch();
  const { id, disabled } = useFormEntityContext();
  const { useRegister } = useEntity(id);
  const columnInputProps = useRegister('left.column')
  const colDef = useColumnDef(columnInputProps?.value);

  const popoverWidth = useColumnPickerFormWidth();
  const { bindTrigger, popoverApi, popoverProps } = Popover.usePopover({
    anchorPosition: 'left',
    maxWidth: popoverWidth
  });

  const handleUpdateColumn = useHandleUpdateColumn(colDef, columnInputProps, popoverApi);


  return (
    <Box
      className={clsx(className, classes.root)}
      display="flex"
      alignItems="center"
    >
      <Button
        {...ButtonStyleProps}
        className={clsx(
          classes.left,
          classes.colDefbutton,
          inputClasses.button,
          inputClasses.buttonAsInput,
          inputClasses.backgroundButton
        )}
        disabled={disabled}
        aria-label="Open column selection popup"
        title={colDef?.label}
        ref={columnInputProps?.ref}
        {...bindTrigger}
      >
        <ColumnIconSelector name={colDef?.name} floating />
        {colDef?.label || 'Select...'}
      </Button>
      <Popover {...popoverProps}>
        <ColumnPickerForm onSelect={handleUpdateColumn} />
      </Popover>
      {(colDef?.allowNull && colDef.allowNull === ALLOW_NULL.USER_CONTROLLED) && (
        <ExtraOptionsPopover />
      )}
      {(colDef?.timeSlice) && (
        <SliceIconButton
          text={false}
          disabled={disabled}
          onClick={() => {
            dispatch({ type: CREATE_SLICE_GROUP, payload: id });
          }}
        />
      )}
    </Box>
  );
}


/**
 * Like a RegularHeader, but shows a Time Slice button.
 * Upon clicking the button, another FormEntity row will render below this one, with
 * Time Slice inputs and controls.
 *
 * The first TimeSlice in a group is the "parent", its time cannot be changed.
 * Every slice afterwords is a child, it can have its Time changed, however it
 * does not display the Col Name or Column Select dropdown.
 *
 * Changing the column can only be done from the parent.
 *
 * @component
 */
function TimeSliceHeader({ className }) {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const dispatch = useFormDispatch();
  const { id, disabled } = useFormEntityContext();
  const { groupId, groupType } = useFormGroupContext();
  const { useRegister } = useEntity(id);
  const columnInputProps = useRegister('left.column');
  const timeSliceInputProps = useRegister('startTime'); // value is ignored, decided from sliceMeta
  const colDef = useColumnDef(columnInputProps?.value);
  const sliceMeta = useEntitySlices(id, groupId, groupType);
  const { index, start, end, slices } = sliceMeta;

  const popoverWidth = useColumnPickerFormWidth();
  const { bindTrigger, popoverApi, popoverProps } = Popover.usePopover({
    anchorPosition: 'left',
    maxWidth: popoverWidth
  });

  const handleUpdateColumn = useHandleUpdateColumn(colDef, columnInputProps, popoverApi);

  const first = index === 0;
  const last = index === slices.length - 1;


  return (
    <Box className={clsx(className)}>
      <Box
        // className={clsx(className, classes.root)}
        display="flex"
        alignItems="center"
      >
        <Switch expr={first}>
          <Case when={true}>
            <Button
              {...ButtonStyleProps}
              aria-label="Open column selection popup"
              className={clsx(
                classes.left,
                classes.colDefbutton,
                inputClasses.button,
                inputClasses.buttonAsInput,
                inputClasses.backgroundButton
              )}
              disabled={disabled}
              title={colDef?.label}
              ref={columnInputProps?.ref}
              {...bindTrigger}
            >
              <ColumnIconSelector name={colDef?.name} floating />
              {colDef?.label || 'Select...'}
            </Button>
            <Popover {...popoverProps}>
              <ColumnPickerForm onSelect={handleUpdateColumn} />
            </Popover>
          </Case>
          <Case default>
            <div className={clsx(classes.left, classes.childSliceIconCont)}>
              <div className={classes.childSliceIcon}></div>
            </div>
          </Case>
        </Switch>
        <TimeSliceInput
          className={clsx(inputClasses.timeSliceControl)}
          id={id}
          start={start}
          end={end}
          slices={slices}
          disabled={disabled || first}
          innerRef={timeSliceInputProps?.ref}
          onChange={timeSliceInputProps?.onChange}
          error={timeSliceInputProps?.error}
        />
      </Box>
      {last && (
        <Box
          className={clsx(classes.left, classes.bottomSliceButton)}
          display="flex"
          justifyContent="flex-end"
        >
          <SliceIconButton
            text={false}
            disabled={disabled}
            onClick={() => {
              dispatch({
                type: CREATE_SLICE,
                payload: { groupId, data: {} }
              });
            }}
          />
        </Box>
      )}
    </Box>
  );
}
