
import React, { useMemo, useState, useCallback, useEffect, useImperativeHandle, forwardRef } from 'react';
import { ReactRenderer } from '@tiptap/react'
import _uniqueId from 'lodash/uniqueId'
import ColumnPickerForm, { useColumnPickerFormWidth } from '../../FilterForm/elements/ColumnPickerForm.js';
import Popover from 'src/app/slicedForm/FilterForm/elements/Popover';
import clsx from "clsx";
import {
  Fade,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    maxWidth: props => props.maxWidth,
    maxHeight: props => props.maxHeight,
    width: '100%',
    height: '100%'
  }
}));


/**
 * This popup is opened from WITHIN tiptap, triggered by the @mention plugin.
 *
 * It shows the regular simple ColumnPopupForm, and communicates the selection 
 * to tiptap
 **/
const ExpressionColumnSuggestionPopup = forwardRef((props, ref) => {
  const {
    className,
    paperClassName,
    decorationNode,
    clientRect,
    items,
  } = props;
  const popoverWidth = useColumnPickerFormWidth();
  const classes = useStyles({ maxWidth: popoverWidth, maxHeight: 500 });
  const [escaped, setEscaped] = useState(false)
  const [id] = useState(() => _uniqueId('expression-col-pop_'))

  const open = Boolean(decorationNode && clientRect && !escaped)
  const _id = open ? id : undefined

  const selectItem = (columnDef) => {
    props.command({
      id: columnDef.name,
      label: columnDef.label,
    });
  }

  return (
    <Popover
      className={clsx(className, classes.root)}
      classes={{
        paper: clsx(paperClassName, classes.paper, 'tiptap-expression-input-skip-blur')
      }}
      open={open}
      anchorEl={open && decorationNode}
      id={_id}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={() => setEscaped(true)}
      TransitionComponent={Fade}
      transitionDuration={100}
    >
      <ColumnPickerForm
        onSelect={selectItem}
        columnDefs={items}
      />
    </Popover>
  )
});

ExpressionColumnSuggestionPopup.displayName = 'Pop';

export default ExpressionColumnSuggestionPopup;

