import _find from 'lodash/find';


export const generateProfileFilenameVersion = (filename, activeProfileId, allProfiles, fileNameKey = 'profileName') => {

  if(!_find(allProfiles, { [fileNameKey]: filename })) {
    return filename;
  }

  const currentInList = _find(allProfiles, { id: activeProfileId });
  if(currentInList && currentInList[fileNameKey] === filename) {
    return filename;
  }

  let version = parseVersion(filename);
  let newName;
  if(!version) {
    newName = `${filename} (1)`;
  } else {
    let nameBase = filename.substring(0, filename.lastIndexOf('(') - 1);
    newName = `${nameBase} (${++version})`;
  }

  return generateProfileFilenameVersion(newName, activeProfileId, allProfiles, fileNameKey);
}



const parseVersion = (filename) => {
  let num = filename.substring(
    filename.lastIndexOf("(") + 1,
    filename.lastIndexOf(')')
  )
  return parseInt(num) ? parseInt(num) : 0;
}



export const generateSimpleFilenameVerson = (filename, filenames) => {
  if (!filenames.includes(filename)) {
    return filename;
  }

  let version = parseVersion(filename);
  let newName;

  if (!version) {
    newName = `${filename} (1)`
  } else {
    let nameBase = filename.substring(0, filename.lastIndexOf('(') - 1);
    newName = `${nameBase} (${++version})`;
  }

  return generateSimpleFilenameVerson(newName, filenames);
}


