import _merge from 'lodash/merge';
import { alpha, createTheme as createMuiTheme } from '@material-ui/core';
import typography from './typography';
import { strongShadows } from './shadows';
import { THEMES } from '../constants';


export const STATIC_ELEMENT_HEIGHTS = {
  panelHeader: 32,
  tableHeader: 26,
  tickerTitleBar: 88,
  tickerTabs: 38,
  paginationBar: 33,
  tableCell: 30.16,
  dashboardChart: 556,
  historyChart: 514
}

const baseConfig = {
  direction: 'ltr',
  typography,
  drawerWidth: 65,
  mobileTopBarHeight: 40,
  grid: {
    containerHorizontalSpacing: 1.6,
    containerVerticalSpacing: 1,
    gutterSpacing: 1,
    borderRadius: 4
  },
  fontSize: {
    icon: '1.5rem',     // 24px
    title: '1.0625rem', // 17px
    tab: '0.9375rem',   // 15px
    button: '.875rem',  // 14px
    label: '.8125rem',  // 13px
    data: '.75rem',     // 12px
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'capitalize',
        fontSize: '0.95rem'
      }
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: STATIC_ELEMENT_HEIGHTS.paginationBar
      }
    }
  }
};

const themeConfigs = [
  {
    name: THEMES.ONE_DARK,
    focus: {
      outline: {
        outline: 'none',
        boxShadow: `0 0 0 2px ${alpha('#8a85ff', .4)} inset`
      }
    },
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        historyFilterSelected: '#22293b',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        light: '#2d313b',
        dark: '#1C2531',
        paper: '#293142',
        paperAlt: '#222B3E',
        paperAlt_darker1: '#242A37',
        paperAlt_highlight: '#3B4150',
        tab: '#1c1c1c',
        fixedTableHeader: '#444964',
        lightBorder: '#363D48',
        lightInput: '#293142',
        panelHeader: '#181F2A',
        panelBorder: '#2A2E36',
        negative: '#EF5350',
        positive: '#26A69A',
        tvChart: '#14151a',
        ssr: '#FF6D6C',
        darkBlue: '#1f2739'
      },
      historical: {
        icon: '#DFCF00',
        // border: '#fadb3f',
        border: '#A59900',
      },
      primary: {
        main: '#8a85ff',
        darker: '#6131FF'
      },
      secondary: {
        main: '#8a85ff'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
        negative: '#f75419',
        positive: '#1ED26F',
        label: '#777A82',
        gray: '#ADADAD'
      },
      chart: {
        gridLines: '#42464D'
      },
      alarms: {
        less: '#EF5350',
        between: '#9CB537',
        greater: '#26A69A'
      }
    },
    shadows: strongShadows
  }
];

export function createTheme(settings = {}) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  return createMuiTheme(
    _merge(
      {},
      baseConfig,
      themeConfig,
      { direction: settings.direction }
    )
  );
}
