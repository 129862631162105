import { initialFinancialsAlarms } from '../profileSettings/profileSettingsConfig';
import { PREDEF_PREFIX } from 'src/redux/profileSettings/profileSettingsConfig';
import { ARRAY_OPS, BOOLEAN_OPS, DATE_TYPES, ROLLING_DATE_OPS } from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import { shareGroupOptions } from 'src/app/slicedForm/FilterForm/definitions/dropdownOptions';


/**
 * Immutably add { predefined: true } to all profiles
 * @param {object[]} profiles
 * @returns {object[]}
 */
const addPredefinedKey = (profiles) => {
  return profiles.map(p => ({ predefined: true, ...p }));
}

//************************ SCANNER ************************//


export const predefinedLayoutScannerColumnProfiles = addPredefinedKey([
  {
    id: `${PREDEF_PREFIX}default`,
    name: 'Default',
    columns: [
      { column: 'index' },
      { column: 'ticker' },
      { column: 'total_news_count' },
      { column: 'session_lp' },
      { column: 'session_v' },
      { column: 'session_chg_p' },
      { column: 'sharesfloat' },
      { column: 'marketcap' },
    ]
  },
  {
    id: `${PREDEF_PREFIX}premarket`,
    name: 'Premarket',
    columns: [
      { column: 'index' },
      { column: 'ticker' },
      { column: 'total_news_count' },
      { column: 'session_lp' },
      { column: 'pm_v' },
      { column: 'pm_chg_p' },
      { column: 'sharesfloat' },
      { column: 'marketcap' },
    ]
  },
  {
    id: `${PREDEF_PREFIX}after_hours`,
    name: 'After Hours',
    columns: [
      { column: 'index' },
      { column: 'ticker' },
      { column: 'total_news_count' },
      { column: 'session_lp' },
      { column: 'ah_v' },
      { column: 'ah_chg_p' },
      { column: 'sharesfloat' },
      { column: 'marketcap' },
    ]
  }
]);


export const predefinedLayoutScannerFilterProfiles = [
  ...addPredefinedKey([
    {
      id: `${PREDEF_PREFIX}default`,
      name: 'Default',
      filters: {
        AND: [
          {
            allowNull: true,
            left: {
              column: 'share_group',
              value: null,
              type: 'column',
            },
            operator: ARRAY_OPS.IN,
            right: [{
              value: [shareGroupOptions[0].name],
              column: null,
              type: 'value'
            }],
          }
        ]
      }
    },
    {
      id: `${PREDEF_PREFIX}small-cap`,
      name: 'Small Cap',
      filters: {
        AND: [
          {
            left: {
              column: 'session_lp',
              value: null,
              type: 'column',
            },
            operator: BOOLEAN_OPS.BTW,
            right: [
              {
                value: 2,
                column: null,
                type: 'value'
              },
              {
                value: 15,
                column: null,
                type: 'value'
              }
            ],
          },
          {
            left: {
              column: 'session_chg_p',
              value: null,
              type: 'column',
            },
            operator: BOOLEAN_OPS.GE,
            right: [
              {
                value: 10,
                column: null,
                type: 'value'
              },
            ],
          },
          {
            left: {
              column: 'session_v',
              value: null,
              type: 'column',
            },
            operator: BOOLEAN_OPS.GE,
            right: [
              {
                value: 300_000,
                column: null,
                type: 'value'
              },
            ],
          },
          {
            allowNull: true,
            left: {
              column: 'share_group',
              value: null,
              type: 'column',
            },
            operator: ARRAY_OPS.IN,
            right: [{
              value: [shareGroupOptions[0].name],
              column: null,
              type: 'value'
            }],
          }
        ]
      }
    },
    {
      id: `${PREDEF_PREFIX}large-cap`,
      name: 'Large Cap',
      filters: {
        AND: [
          {
            left: {
              column: 'session_lp',
              value: null,
              type: 'column',
            },
            operator: BOOLEAN_OPS.BTW,
            right: [
              {
                value: 15,
                column: null,
                type: 'value'
              },
              {
                value: 100,
                column: null,
                type: 'value'
              }
            ],
          },
          {
            left: {
              column: 'session_chg_p',
              value: null,
              type: 'column',
            },
            operator: BOOLEAN_OPS.GE,
            right: [
              {
                value: 5,
                column: null,
                type: 'value'
              },
            ],
          },
          {
            left: {
              column: 'session_v',
              value: null,
              type: 'column',
            },
            operator: BOOLEAN_OPS.GE,
            right: [
              {
                value: 300_000,
                column: null,
                type: 'value'
              },
            ],
          },
          {
            allowNull: true,
            left: {
              column: 'share_group',
              value: null,
              type: 'column',
            },
            operator: ARRAY_OPS.IN,
            right: [{
              value: [shareGroupOptions[0].name],
              column: null,
              type: 'value'
            }],
          }
        ]
      }
    },
    {
      id: `${PREDEF_PREFIX}afterhours-scanner`,
      name: 'Afterhours Scanner',
      filters: {
        AND: [
          {
            left: {
              column: 'session_lp',
              value: null,
              type: 'column',
            },
            operator: BOOLEAN_OPS.BTW,
            right: [
              {
                value: 2,
                column: null,
                type: 'value'
              },
              {
                value: 15,
                column: null,
                type: 'value'
              }
            ],
          },
          {
            left: {
              column: 'ah_chg_p',
              value: null,
              type: 'column',
            },
            operator: BOOLEAN_OPS.GE,
            right: [
              {
                value: 10,
                column: null,
                type: 'value'
              },
            ],
          },
          {
            left: {
              column: 'ah_v',
              value: null,
              type: 'column',
            },
            operator: BOOLEAN_OPS.GE,
            right: [
              {
                value: 50_000,
                column: null,
                type: 'value'
              },
            ],
          },
          {
            allowNull: true,
            left: {
              column: 'share_group',
              value: null,
              type: 'column',
            },
            operator: ARRAY_OPS.IN,
            right: [{
              value: [shareGroupOptions[0].name],
              column: null,
              type: 'value'
            }],
          }
        ]
      }
    }
  ])
]





//************************ KEYSTATS ************************//

export const KEYSTATS_EXTRA_CHECKBOXES = [
  { name: 'show_stock_price', label: 'Stock Price' },
];

const checkboxDefaultState = KEYSTATS_EXTRA_CHECKBOXES.reduce((acc, cb) => {
  acc[cb.name] = true;
  return acc;
}, {});


export const predefinedLayoutKeyStatsProfiles = addPredefinedKey([
  {
    id: `${PREDEF_PREFIX}small-cap`,
    name: 'Small Cap',
    alarms: {
      'marketCap': { less: 150_000_000, greater: 500_000_000, reversed: true },
      'sharesoutstanding': { less: 3_000_000, greater: 10_000_000, reversed: false },
      'sharesfloat': { less: 3_000_000, greater: 10_000_000, reversed: false },
      'shortpercentfloat': { less: 10, greater: 30, reversed: true },
      'percentinstitutions': { less: 10, greater: 30, reversed: true },
      'percentinsiders': { less: 20, greater: 50, reversed: true },
      'floatRotation': { less: 3, greater: 10, reversed: true },
      ...initialFinancialsAlarms
    },
    extra: { ...checkboxDefaultState },
    sections: [
      {
        sectionName: 'General Info',
        tables: [
          ['marketCap', 'sharesoutstanding', 'sharesfloat', 'shortpercentfloat', 'percentinstitutions', 'percentinsiders', 'floatRotation'],
          ['SSR', 'rvolCalculated', 'day365_high_combined', 'day365_low_combined', 'changeYTDCombined', 'lastSplitCombined', 'next_earnings']
        ],
      },
      {
        sectionName: 'Financials',
        tables: [
          ['CashAndCashEquivalents', 'MonthsOfCash', 'WorkingCapital'],
          ['TotalRevenue', 'CurrentRatio', 'BurnMo']
        ],
      }
    ]
  },

  {
    id: `${PREDEF_PREFIX}large-cap`,
    name: 'Large Cap',
    alarms: {
      'marketCap': { less: 500_000_000, greater: 10_000_000_000, reversed: false },
      'sharesoutstanding': { less: 100_000_000, greater: 500_000_000, reversed: false },
      'sharesfloat': { less: 100_000_000, greater: 500_000_000, reversed: false },
      'shortpercentfloat': { less: 10, greater: 30, reversed: false },
      'percentinstitutions': { less: 10, greater: 30, reversed: false },
      'percentinsiders': { less: 20, greater: 50, reversed: true },
      ...initialFinancialsAlarms
    },
    extra: { ...checkboxDefaultState },
    sections: [
      {
        sectionName: 'General Info',
        tables: [
          ['marketCap', 'sharesoutstanding', 'sharesfloat', 'shortpercentfloat', 'percentinstitutions', 'percentinsiders', 'industry'],
          ['SSR', 'rvolCalculated', 'day365_high_combined', 'day365_low_combined', 'changeYTDCombined', 'lastSplitCombined', 'next_earnings']
        ],
      },
      {
        sectionName: 'Financials',
        tables: [
          ['pe_ratio', 'DebtEquityRatio', 'CurrentRatio'],
          ['BasicEPS', 'ROA', 'ROE']
        ],
      }
    ]
  },
]);


//************************ NEWS/SEC ************************//



export const defaultLayoutNewsColumnProfiles = addPredefinedKey([
  {
    id: `${PREDEF_PREFIX}price`,
    name: 'Price',
    columns: [
      { column: 'ticker' },
      { column: 'combinedHeadline' },
      { column: 'session_lp' },
      { column: 'session_v' },
      { column: 'session_chg_p' }
    ]
  },
  {
    id: `${PREDEF_PREFIX}basic`,
    name: 'Basic',
    columns: [
      { column: 'ticker' },
      { column: 'combinedHeadline' }
    ]
  },
  {
    id: `${PREDEF_PREFIX}volume`,
    name: 'Volume',
    columns: [
      { column: 'ticker' },
      { column: 'combinedHeadline' },
      { column: 'session_lp' },
      { column: 'session_v' },
      { column: 'rvol' }
    ]
  },
  {
    id: `${PREDEF_PREFIX}share`,
    name: 'Share',
    columns: [
      { column: 'ticker' },
      { column: 'combinedHeadline' },
      { column: 'session_lp' },
      { column: 'session_chg_p' },
      { column: 'sharesfloat' }
    ]
  }
]);


//******************** HISTORICAL (GAP/HISTORY) ********************//


export const defaultLayoutHistoryColumnProfiles = addPredefinedKey([
  {
    id: `${PREDEF_PREFIX}default`,
    name: 'Default',
    columns: [
      { column: 'day0_date' },
      { column: 'day0_open' },
      { column: 'day0_vol' },
      { column: 'day0_gap_pct' },
      { column: 'day0_chg_pct' },
      { column: 'day0_high_time' },
      { column: 'day0_low_time' },
    ],
  },
  {
    id: `${PREDEF_PREFIX}gappers`,
    name: 'Gappers',
    columns: [
      { column: 'day0_date' },
      { column: 'day0_open' },
      { column: 'day0_vol' },
      { column: 'day0_pm_high_pct' },
      { column: 'day0_gap_pct' },
      { column: 'day0_open_high_pct' },
      { column: 'day0_high_time' },
      { column: 'day0_low_time' },
    ],
  },
]);


export const defaultLayoutHistoryFilterProfiles = addPredefinedKey([
  {
    id: `${PREDEF_PREFIX}small-cap`,
    name: 'Small Cap',
    filters: {
      AND: [
        {
          left: { column: 'day0_date', type: 'column' },
          dateType: DATE_TYPES.ROLLING,
          operator: ROLLING_DATE_OPS.GE,
          right: [{ value: 'rolling_14_years', type: 'value' }]
        },
        {
          left: { column: 'day0_gap_pct', type: 'column' },
          operator: BOOLEAN_OPS.GE,
          right: [{ value: 10, type: 'value' }]
        },
        {
          left: { column: 'day0_vol', type: 'column' },
          operator: BOOLEAN_OPS.GE,
          right: [{ value: 1_000_000, type: 'value' }]
        }
      ]
    }
  },
  {
    id: `${PREDEF_PREFIX}large-cap`,
    name: 'Large Cap',
    filters: {
      AND: [
        {
          left: { column: 'day0_date', type: 'column' },
          dateType: DATE_TYPES.ROLLING,
          operator: ROLLING_DATE_OPS.GE,
          right: [{ value: 'rolling_14_years', type: 'value' }]
        },
        {
          left: { column: 'day0_chg_pct', type: 'column' },
          operator: BOOLEAN_OPS.GE,
          right: [{ value: 5, type: 'value' }]
        },
        {
          left: { column: 'day0_vol', type: 'column' },
          operator: BOOLEAN_OPS.GE,
          right: [{ value: 1_000_000, type: 'value' }]
        }
      ]
    },
  },

]);


// TODO: TEMP_EXPRESSION
// export const defaultScannerExpressionProfiles = [
//   {
//     name: 'Example Expression',
//     id: 'default_expression',
//     format: 'arbitrary',
//     expression: '(100 - [[A]]) / (10 * [[B]])',
//     args: {
//       A: { column: 'session_lp' },
//       B: { column: 'o_chg' }
//     }
//   }
// ]

