import { STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import _mapValues from 'lodash/mapValues';
import { unflattenPathsToObject } from '../flatpath.js';

/**  @typedef {import('./index.js').FormStruct} FormStruct */


export default function FormToProfile(state) {
  const entities = state.entities;
  const profiles = [];


  /******************/
  /***** SHARED *****/
  /******************/

  const out = () => {
    state.profiles.forEach(mapProfile);
    return {
      profiles,
      activeProfile: state.activeProfile,
      expressions: state.expressions,
    };
  };


  const decideNodeType = (node) => {
    if (!node || !(typeof node === 'object')) return null;
    return node?.type;
  };


  /** @param {FormStruct} profile */
  const mapProfile = (profile) => {
    const { root, ...rest } = profile;

    const recurse = (node) => {
      const type = decideNodeType(node);

      switch (type) {
        case STRUCTURAL_TYPES.AND:
        case STRUCTURAL_TYPES.OR:
        case STRUCTURAL_TYPES.SLICE_GROUP: {
          return {
            [type]: node.tree.map(recurse)
          };
        }
        case STRUCTURAL_TYPES.FILTER: {
          return unflattenPathsToObject(_mapValues(entities[node.id], e => e?.val));
        }
        default: {
          throw new Error(`Unknown node type "${type}", node ${JSON.stringify(node)}}`);
        }
      }
    };

    const filters = recurse(root);

    profiles.push({
      filters,
      ...rest
    });
  };


  return out();
}
