import React, { useMemo, useCallback } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import clsx from 'clsx';
import { format, isValid } from 'date-fns';
import { parseAssumeMarketTime } from 'src/utils/datetime/date-fns.tz';
import { extendedHoursDisabledTimes } from 'src/app/components/pickers/definitions/disabledTimes';
import {
  BOOLEAN_OP_LABELS,
  BOOLEAN_OPS,
  DATE_TYPES,
  VALUE_TYPE_LABELS,
  VALUE_TYPES
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import useInputStyles, {
  InputStyleProps,
  ButtonStyleProps
} from 'src/app/slicedForm/shared/styles/useInputStyles';
import { useEntity } from '../context/useFormEntity';
import { useColumnDef } from 'src/app/slicedForm/shared/context/ColumnDefsProvider';
import { getFilterEntityDefaults } from 'src/app/slicedForm/shared/schema/schemaBuilder';
import { useFormEntityContext } from 'src/app/slicedForm/FilterForm/context/FormEntityContext';
import { Switch, Case } from 'src/app/components/utility/SwitchCase';
import SimpleDropdown from '../elements/SimpleDropdown';
import PopoverKeyboardTimePicker from 'src/app/components/pickers/PopoverKeyboardTimePicker';
import PopoverKeyboardTimeRangePicker from 'src/app/components/pickers/PopoverKeyboardTimeRangePicker';
import Popover from 'src/app/slicedForm/FilterForm/elements/Popover';
import useComparableColDefs from 'src/app/slicedForm/shared/hooks/useComparableColDefs';
import ColumnPickerForm, { useColumnPickerFormWidth } from 'src/app/slicedForm/FilterForm/elements/ColumnPickerForm';
import {
  Box,
  Button,
  FormControl,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '& .MuiInputAdornment-root': {
      '& p': {
        fontSize: 14,
      }
    }
  },
  rolling: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flexNegativeMargin: {
    marginTop: -10
  },
  flexMargin: {
    marginTop: 10
  }
}));



const TIME_FMT = 'HH:mm';


function TimeCompare({
  className,
  hideComparison,
  hideValueType
}) {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const { id, disabled } = useFormEntityContext();

  const { useRegister, useWatch } = useEntity(id);

  const leftColumn = useWatch('left.column');
  const rightColumnFirst = useWatch('right[0].column');
  const rightColumnSecond = useWatch('right[1].column');


  const operatorInputProps = useRegister('operator', { refName: 'innerRef', valueName: 'selected' });
  const valueTypeInputProps = useRegister('right[0].type', { refName: 'innerRef' }); // both right0 and right1 will assume this 'type'
  // TODO: Not implemented. Would be useful for "Last Hour, Last 2 Hours, ect..."
  // const timeType = useRegister('timeType', { refName: 'innerRef', valueName: 'selected' });

  const rightFirstValueProps = useRegister('right[0].value');
  const rightFirstColumnProps = useRegister('right[0].column');
  const rightSecondValueProps = useRegister('right[1].value');
  const rightSecondColumnProps = useRegister('right[1].column');

  const range = useMemo(() => {
    return {
      'startDate': rightFirstValueProps?.value ? parseAssumeMarketTime(rightFirstValueProps?.value, TIME_FMT) : null,
      'endDate': rightSecondValueProps?.value ? parseAssumeMarketTime(rightSecondValueProps?.value, TIME_FMT) : null,
    }
  }, [rightFirstValueProps?.value, rightSecondValueProps?.value])

  const popoverWidth = useColumnPickerFormWidth();
  const popover0 = Popover.usePopover({ anchorPosition: 'right', maxWidth: popoverWidth });
  const popover1 = Popover.usePopover({ anchorPosition: 'right', maxWidth: popoverWidth });
  const popovers = [popover0, popover1];

  const leftColDef = useColumnDef(leftColumn);

  const rightColDefFirst = useColumnDef(rightColumnFirst);
  const rightColDefSecond = useColumnDef(rightColumnSecond);

  const comparableColDefs = useComparableColDefs(leftColDef);

  const canCompareColumns = comparableColDefs.length > 0;

  // const defaultDateConfig = useDefaultDateConfig();


  const handleSelectColumnAsValue = (inputProps, colDef, position = 0) => {
    popovers[position]?.popoverApi?.handleClose();

    // TODO: This is hard-coding. We probably want this dynamic. Maybe context?
    const defaults = getFilterEntityDefaults(colDef);
    if (!defaults || !colDef) {
      console.warn(`No defaults/colDef found for column ${colDef}}`);
      return;
    }

    inputProps.onChange(colDef?.name, { colDef });
  };


  const handleTimeChange = (newValue, onChange) => {
    const stamp = format(newValue, TIME_FMT);
    onChange(stamp);
  }

  const handleTimeRangeChange = ({ startDate, endDate }) => {
    const nextVals = [
      isValid(startDate) ? format(startDate, TIME_FMT) : null,
      isValid(endDate) ? format(endDate, TIME_FMT) : null
    ]

    unstable_batchedUpdates(() => {
      [rightFirstValueProps, rightSecondValueProps].forEach((props, i) => {
        props?.onChange(nextVals[i]);
      });
    })
  }


  /**
   * BTW <TIME> has its own special input with both fields.
   * However, BTW <column> must work like the normal <Compare/> component. 
   * So we need individual inputs for each column.
   */
  const renderColumnAsValueInput = (valueProps, columnProps, colDef, index = 0) => {
    if (valueTypeInputProps?.value !== VALUE_TYPES.column) {
      return null;
    }

    return (
      <FormControl
        className={clsx(
          inputClasses.formControl,
          inputClasses.valueInputControl,
          classes.flexMargin,
          columnProps.error && inputClasses.formControlError,
          columnProps.error && 'sf-form-control-error-flash'
        )}
        error={Boolean(columnProps.error)}
      >
        <Button
          aria-label="Open column selection popup"
          {...ButtonStyleProps}
          disabled={disabled}
          className={clsx(
            inputClasses.button,
            inputClasses.highlight,
            inputClasses.buttonAsInput,
          )}
          ref={columnProps.ref}
          {...popovers[index].bindTrigger}
        >
          {colDef?.label || 'Select...'}
        </Button>
        <Popover {...popovers[index].popoverProps}>
          <ColumnPickerForm
            columnDefs={comparableColDefs}
            onSelect={(newColDef) => handleSelectColumnAsValue(columnProps, newColDef, index)}
          />
        </Popover>
      </FormControl>
    )
  }


  return (
    <Box className={clsx(className, classes.root)}>

      <Switch expr={hideComparison}>
        <Case when={true}>
          <FormControl className={clsx(
            inputClasses.formControl,
            inputClasses.comparisonSelectControl
          )} />
        </Case>
        <Case default>
          <SimpleDropdown
            className={clsx(
              inputClasses.formControl,
              inputClasses.comparisonSelectControl
            )}
            options={BOOLEAN_OP_LABELS}
            label="Select comparison operator"
            disabled={disabled}
            {...operatorInputProps}
          />
        </Case>
      </Switch>

      <Switch expr={hideValueType || !canCompareColumns}>
        <Case when={true}>
          <FormControl
            className={clsx(
              inputClasses.formControl,
              inputClasses.valueTypeSelectControl
            )}
          />
        </Case>
        <Case default>
          <SimpleDropdown
            className={clsx(
              inputClasses.formControl,
              inputClasses.valueTypeSelectControl
            )}
            options={VALUE_TYPE_LABELS}
            label="Select value type"
            disabled={disabled}
            selected={valueTypeInputProps.value}
            {...valueTypeInputProps}
          />
        </Case>
      </Switch>

      <Box
        display="flex"
        flexWrap="wrap"
        flex={1}
        className={classes.flexNegativeMargin}
      >
        <Switch expr={valueTypeInputProps?.value === VALUE_TYPES.value} log>
          <Case when={true}>
            <Switch expr={operatorInputProps?.selected === BOOLEAN_OPS.BTW}>
              <Case when={true}>
                {/* TIME_RANGE: Value && BTW */}
                <PopoverKeyboardTimeRangePicker
                  rangeClassName={clsx(
                    inputClasses.calendarAdornment,
                    inputClasses.calendarAdornmentFakeRange,
                    inputClasses.highlight,
                    inputClasses.formControl,
                    inputClasses.singleRangeInputControl,
                    classes.flexMargin,
                    {
                      [`${inputClasses.formControlError} sf-form-control-error-flash`]: rightFirstValueProps?.error || rightSecondValueProps?.error
                    }
                  )}
                  flexWrap="wrap"
                  inputClassName={clsx(
                    inputClasses.input,
                    inputClasses.fakeSingleRangeInput,
                    inputClasses.fakeSingleRangeTimeInput
                  )}
                  leftInputClassName={clsx('--left')}
                  rightInputClassName={clsx('--right')}
                  {...InputStyleProps}
                  disableUnderline
                  showErrorMessage
                  errorPositionAbsolute
                  showNowButton={false}
                  backgroundOpacity={0}
                  range={range}
                  separator={'—'}
                  format={TIME_FMT}
                  placeholder={'hh:mm'}
                  disabledTime={extendedHoursDisabledTimes}
                  marketTime
                  steps={{
                    hours: 1,
                    minutes: 5,
                    seconds: 1
                  }}
                  onAccept={handleTimeRangeChange}
                />
              </Case>
              <Case default>
                <PopoverKeyboardTimePicker
                  inputClassName={clsx(
                    inputClasses.input,
                    inputClasses.highlight,
                    inputClasses.formControl,
                    inputClasses.valueInputControl,
                    inputClasses.calendarAdornment,
                    classes.flexMargin,
                    rightFirstValueProps?.error && inputClasses.formControlError,
                    rightFirstValueProps?.error && 'sf-form-control-error-flash'
                  )}
                  {...InputStyleProps}
                  disableUnderline
                  showErrorMessage
                  errorPositionAbsolute
                  showNowButton
                  backgroundOpacity={0}
                  date={range?.startDate}
                  format={TIME_FMT}
                  placeholder={'hh:mm'}
                  disabledTime={extendedHoursDisabledTimes}
                  marketTime
                  steps={{
                    hours: 1,
                    minutes: 5,
                    seconds: 1
                  }}
                  onAccept={val => handleTimeChange(val, rightFirstValueProps.onChange)}
                />
                {/* </FormControl> */}
              </Case>
            </Switch>
          </Case>
          <Case default>
            {/* Single col input */}
            {renderColumnAsValueInput(rightFirstValueProps, rightFirstColumnProps, rightColDefFirst, 0)}

            {/* Double col input */}
            {Boolean(operatorInputProps.selected === BOOLEAN_OPS.BTW) &&
              renderColumnAsValueInput(rightSecondValueProps, rightSecondColumnProps, rightColDefSecond, 1)
            }
          </Case>
        </Switch>
      </Box>
    </Box>
  );
}

export default TimeCompare;


/*

IF > and Time:
  [time input]
if > and Col
  [col input]
if BTW and Time
  [Time Range Input]
if BTW and Col
  [col input] [col input]
*/
