import { renderTickerSSRCell } from 'src/app/components/grid/gridColumnFunctions';
import {
  ALLOW_NULL,
  DATA_TYPES,
  INPUTS,
  STICKY_OPTIONS,
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import {
  integerToBooleanOptions,
} from 'src/app/slicedForm/FilterForm/definitions/dropdownOptions';
import {
  BASE_SYNONYMS,
} from 'src/app/slicedForm/shared/schema/synonyms';
import {
  SchemaBuilder,
  validateColDefs
} from 'src/app/slicedForm/shared/schema/schemaBuilder';
import { getColumnDefsFor, attachSynonymsToColumns } from '../../buildColumns';
import { TOP_LIST_ROLLING_DATES } from 'src/app/components/pickers/definitions/staticRangeDefinitions';
import { integerToBooleanFormatter } from 'src/app/components/grid/gridColumnFunctions.js'



/** Visual groupings of columns on the frontend */
export const GROUPS = {
  DAY0_METRICS: {
    label: 'Day 1 Metrics',
    name: 'DAY0_METRICS',
  },
  DAY1_METRICS: {
    label: 'Day 1 Metrics',
    name: 'DAY1_METRICS',
  }
};


const SCHEMA = new SchemaBuilder({ noTimeSlice: true });



export const ALL_COLUMNS = validateColDefs([
  SCHEMA.date({
    name: 'day0_date',
    label: 'D[1] Date',
    formLabel: 'Date Range',
    group: GROUPS.DAY0_METRICS.name,
    grid: {
      width: 96,
      pinned: 'left',
      suppressSizeToFit: true,
      suppressAutoSize: true,
    },
    filter: {
      sticky: STICKY_OPTIONS.STICKY,
      stickyIndex: 0,
      label: 'Date Range'
    },
    column: {
      sticky: STICKY_OPTIONS.STICKY,
      stickyIndex: 0,
      label: 'Date'
    },
    dateConfig: {
      disableFuture: true,
      disableWeekend: false,
      disableHoliday: false,
      rollingDates: TOP_LIST_ROLLING_DATES,
      defaultRollingDate: 'rolling_30_days',
      makeInputDates: undefined,
    }
  }),
  SCHEMA.price({
    name: 'day0_open',
    label: 'D[1] Open',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_high',
    label: 'D[1] High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_low',
    label: 'D[1] Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_close',
    label: 'D[1] Close',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_vol',
    label: 'D[1] Vol',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_vw',
    label: 'D[1] VW',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_range',
    label: 'D[1] Range',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_chg',
    label: 'D[1] Chg',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg_pct',
    label: 'D[1] Chg %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_chg2',
    label: 'D[1] Chg2',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg2_pct',
    label: 'D[1] Chg2 %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_chg3',
    label: 'D[1] Chg3',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg3_pct',
    label: 'D[1] Chg3 %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_chg4',
    label: 'D[1] Chg4',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg4_pct',
    label: 'D[1] Chg4 %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_chg5',
    label: 'D[1] Chg5',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg5_pct',
    label: 'D[1] Chg5 %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_gap',
    label: 'D[1] Gap',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_gap_pct',
    label: 'D[1] Gap %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ret_open',
    label: 'D[1] Ret Open',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_ret_open_pct',
    label: 'D[1] Ret Open %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.multiSelect({
    name: 'day0_open_ssr',
    label: 'D[1] Open SSR',
    group: GROUPS.DAY0_METRICS.name,
    dtype: DATA_TYPES.STRING,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter
    }
  }),
  SCHEMA.multiSelect({
    name: 'day0_close_ssr',
    label: 'D[1] Close SSR',
    group: GROUPS.DAY0_METRICS.name,
    dtype: DATA_TYPES.STRING,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter
    }
  }),
  SCHEMA.percentage({
    name: 'day0_open_high_pct',
    label: 'D[1] Open High %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_open_low_pct',
    label: 'D[1] Open Low %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_high_low_pct',
    label: 'D[1] High Low %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_close_high_pct',
    label: 'D[1] Close High %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_close_low_pct',
    label: 'D[1] Close Low %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_range_close_pct',
    label: 'D[1] Range Close %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_dollar_vol',
    label: 'D[1] Dollar Vol',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day0_consec_green',
    label: 'D[1] Consec Green',
    dtype: DATA_TYPES.INT,
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day0_consec_red',
    label: 'D[1] Consec Red',
    dtype: DATA_TYPES.INT,
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day0_consec_gap_up',
    label: 'D[1] Consec Gap Up',
    dtype: DATA_TYPES.INT,
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day0_consec_gap_down',
    label: 'D[1] Consec Gap Down',
    dtype: DATA_TYPES.INT,
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_open',
    label: 'D[2] Open',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_high',
    label: 'D[2] High',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_low',
    label: 'D[2] Low',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_close',
    label: 'D[2] Close',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day1_vol',
    label: 'D[2] Vol',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_vw',
    label: 'D[2] VW',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_range',
    label: 'D[2] Range',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_chg',
    label: 'D[2] Chg',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_chg_pct',
    label: 'D[2] Chg %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day1_gap',
    label: 'D[2] Gap',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_gap_pct',
    label: 'D[2] Gap %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day1_ret_open',
    label: 'D[2] Ret Open',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_ret_open_pct',
    label: 'D[2] Ret Open %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_high_low_pct',
    label: 'D[2] High Low %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day1_dollar_vol',
    label: 'D[2] Dollar Vol',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.multiSelect({
    name: 'day1_open_ssr',
    label: 'D[2] Open SSR',
    group: GROUPS.DAY1_METRICS.name,
    dtype: DATA_TYPES.STRING,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter
    }
  }),
  SCHEMA.volume({
    name: 'day0_pm_vol',
    label: 'D[1] PM Vol',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_high',
    label: 'D[1] PM High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_high_time',
    label: 'D[1] PM High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_pm_high_pct',
    label: 'D[1] PM High %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_pm_vol_to_hod',
    label: 'D[1] PM Vol To Hod',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_low',
    label: 'D[1] PM Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_low_time',
    label: 'D[1] PM Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_vol_to_lod',
    label: 'D[1] PM Vol To Lod',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_low_after_high',
    label: 'D[1] PM Low After High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_low_after_high_time',
    label: 'D[1] PM Low After High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_low_before_high',
    label: 'D[1] PM Low Before High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_low_before_high_time',
    label: 'D[1] PM Low Before High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_high_after_low',
    label: 'D[1] PM High After Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_high_after_low_time',
    label: 'D[1] PM High After Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_high_before_low',
    label: 'D[1] PM High Before Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_high_before_low_time',
    label: 'D[1] PM High Before Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_start_time',
    label: 'D[1] PM Start Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day0_pm_open_range_pct',
    label: 'D[1] PM Open Range %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_pm_pullback_open_j_pct',
    label: 'D[1] PM Pullback Open %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_high_time',
    label: 'D[1] High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_vol_to_hod',
    label: 'D[1] Vol To Hod',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_low_time',
    label: 'D[1] Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_vol_to_lod',
    label: 'D[1] Vol To Lod',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_low_after_high',
    label: 'D[1] Low After High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_low_after_high_time',
    label: 'D[1] Low After High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_low_before_high',
    label: 'D[1] Low Before High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_low_before_high_time',
    label: 'D[1] Low Before High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_high_after_low',
    label: 'D[1] High After Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_high_after_low_time',
    label: 'D[1] High After Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_high_before_low',
    label: 'D[1] High Before Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_high_before_low_time',
    label: 'D[1] High Before Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_vol',
    label: 'D[1] AH Vol',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_high',
    label: 'D[1] AH High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_high_time',
    label: 'D[1] AH High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_vol_to_hod',
    label: 'D[1] AH Vol To Hod',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_low',
    label: 'D[1] AH Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_low_time',
    label: 'D[1] AH Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_vol_to_lod',
    label: 'D[1] AH Vol To Lod',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_close',
    label: 'D[1] AH Close',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_chg',
    label: 'D[1] AH Chg',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_ah_chg_pct',
    label: 'D[1] AH Chg %',
    group: GROUPS.DAY0_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ah_low_after_high',
    label: 'D[1] AH Low After High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_low_after_high_time',
    label: 'D[1] AH Low After High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_low_before_high',
    label: 'D[1] AH Low Before High',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_low_before_high_time',
    label: 'D[1] AH Low Before High Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_high_after_low',
    label: 'D[1] AH High After Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_high_after_low_time',
    label: 'D[1] AH High After Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_high_before_low',
    label: 'D[1] AH High Before Low',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_high_before_low_time',
    label: 'D[1] AH High Before Low Time',
    group: GROUPS.DAY0_METRICS.name,
  }),
  SCHEMA.price({
    name: 'day1_pm_high',
    label: 'D[2] PM High',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day1_pm_high_time',
    label: 'D[2] PM High Time',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day1_pm_high_pct',
    label: 'D[2] PM High %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day1_pm_low',
    label: 'D[2] PM Low',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day1_pm_low_time',
    label: 'D[2] PM Low Time',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day1_pm_vol',
    label: 'D[2] PM Vol',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.percentage({
    name: 'day1_open_high_pct',
    label: 'D[2] Open High %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_open_low_pct',
    label: 'D[2] Open Low %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_close_high_pct',
    label: 'D[2] Close High %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_close_low_pct',
    label: 'D[2] Close Low %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_range_close_pct',
    label: 'D[2] Range Close %',
    group: GROUPS.DAY1_METRICS.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day1_high_time',
    label: 'D[2] High Time',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.time({
    name: 'day1_low_time',
    label: 'D[2] Low Time',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day1_vol_to_hod',
    label: 'D[2] Vol To Hod',
    group: GROUPS.DAY1_METRICS.name,
  }),
  SCHEMA.volume({
    name: 'day1_vol_to_lod',
    label: 'D[2] Vol To Lod',
    group: GROUPS.DAY1_METRICS.name,
  }),
], GROUPS);



export const GRID_COLUMNS = getColumnDefsFor('grid', ALL_COLUMNS);
export const FILTER_COLUMNS = attachSynonymsToColumns(
  getColumnDefsFor('filter', ALL_COLUMNS),
  BASE_SYNONYMS
);
export const COLUMN_COLUMNS = attachSynonymsToColumns(
  getColumnDefsFor('column', ALL_COLUMNS),
  BASE_SYNONYMS
);



