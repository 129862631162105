import React, { useState, useMemo, useRef, useContext, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import Split from '@uiw/react-split';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact } from '@ag-grid-community/react';
import fp_filter from 'lodash/fp/filter';
import fp_uniqBy from 'lodash/fp/uniqBy';
import _flow from 'lodash/flow';
import { applyResize, handleKeyboardRowNavigation, onRowSelected } from 'src/utils/agGridFunctions';
import LayoutContext from '../layout/LayoutContext';
import { useDeepCompareEffect, useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import useUserPlanPermissions from 'src/hooks/useUserPlanPermissions';
import { useMakeTickerTTS, useMultiAudio } from 'src/hooks/useAudio';
import useToplistLinkedValues from 'src/hooks/useToplistLinkedValues';
import useStateRef from 'src/hooks/useStateRef';
import IntercomArticleButton, { INTERCOM_ARTICLES } from 'src/app/components/intercom/IntercomArticleButton';
import TopListDataSourceV2, { TRACKER_ACTIONS } from 'src/app/components/grid/topListScanner/dataSource/dataSource';
import { PROFILE_CONFIG } from 'src/redux/layout/topListLayoutSchema';
import {
  GRID_COLUMNS,
  TRACKER_COLUMNS,
} from 'src/app/components/grid/topListScanner/columns/columnDefs';
import {
  rowClassRules,
  buildGridColumns,
} from 'src/app/components/grid/buildColumns';
import { useSyncronizedTimer } from 'src/context/SyncronizedTimerContext';
import {
  selectActiveWatchlistTickers,
  selectComponent,
  selectProfileList,
} from 'src/redux/layout/topListLayoutSelectors';
import {
  updateComponent,
  updateScannerColumnProfiles,
  updateScannerFilterProfiles
} from 'src/redux/layout/topListLayoutActions';
import { makeStaticDaysTopList } from 'src/app/components/pickers/definitions/staticDayDefinitions';
import useMosaicTickerExclude from 'src/app/TopListsMosaic/TopListScanner/useMosaicTickerExclude';
import CompactNumberCell from 'src/app/components/grid/cellRenderers/CompactNumberCell';
import AnimateChangeCellRenderer from 'src/app/components/grid/cellRenderers/AnimateChangeCellRenderer';
import SimpleMessageOverlay from 'src/app/components/grid/overlays/SimpleMessageOverlay';
import MosaicPanel from 'src/app/TopListsMosaic/layout/MosaicPanel';
import MosaicPanelHeader from 'src/app/TopListsMosaic/layout/MosaicPanelHeader/MosaicPanelHeader';
import MosaicPanelBody from 'src/app/TopListsMosaic/layout/MosaicPanelBody';
import SmallFilterWindow from 'src/app/components/filterContainers/SmallFilterWindow';
import ScannerTrackerBar from 'src/app/TopListsMosaic/layout/ScannerTrackerBar';
import SlicedColumnsForm from './forms/SlicedColumnsForm';
import SlicedFiltersForm from './forms/SlicedFiltersForm';
import { MarketOpenIcon } from 'src/theme/EdgeIcons';
import InlineKeyboardDatePicker from 'src/app/components/pickers/InlineKeyboardDatePicker';
import {
  alpha,
  makeStyles,
  useTheme
} from '@material-ui/core';
import {
  formatMarketTime,
  getCurrentTradingDay,
  getMostRecentTradingDay,
  getOldestAllowedTopListDate,
} from 'src/utils/datetime/date-fns.tz';
import { differenceInMilliseconds, format, parse } from 'date-fns';
import TickerExcludePopover from 'src/app/TopListsMosaic/TopListScanner/TickerExcludePopover';
import useScannerContextMenu from 'src/app/components/grid/contextMenu/useScannerContextMenu';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useRealtimeMappedExpressions from 'src/app/components/grid/topListScanner/columns/useRealtimeMappedExpressions';
import useFilterRelevantExpressions from 'src/app/slicedForm/shared/hooks/useFilterRelevantExpressions';
import { AUDIO, DEFAULT_AUDIO, DEFAULT_VOLUME, SPEAK_AUDIO_KEY } from './forms/AudioForm/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .w-split-bar': {
      boxShadow: 'none !important',
    },
    '& .w-split-bar::before, .w-split-bar::after,': {
      content: 'none',
    }
  },
  gridContainer: {
    minHeight: 30,
    flex: 1,
    '& .ag-root-wrapper': {
      borderRadius: '0 !important'
    }
  },
  trackerDrawer: {
    minHeight: 30,
    backgroundColor: theme.palette.background.panelHeader,
    display: 'flex',
    flexDirection: 'column'
  },
  trackerRow: {
    '& > *': {
      flex: 1
    }
  },
  drawerGridCont: {
    flex: 1,
  },
  inlineContainer: {
    '& > .rs-picker-menu': {
      position: 'relative',
      top: 'unset',
      left: 'unset',
      boxShadow: 'unset'
    }
  }
}));




// Must be defined outside selectComponent(), otherwise it will be recreated on every render.
const defaultTrackerOptions = { [TRACKER_ACTIONS.added]: true, [TRACKER_ACTIONS.removed]: false };
const defaultAudioOptions = {
  volume: 0,
  lastVolume: DEFAULT_VOLUME,
  selectedAudio: DEFAULT_AUDIO,
  cooldownTime: 60
};


/**
 * Simple selector between TTS or audio files, based on component state
 * NOTE: The arguments technically should be memo'd first, but I believe
 * the hook will correctly handle that internally by pulling specific props out.
 **/
function useMakeTriggerAudioOrTTS(
  { selectedAudio },
  audioHookOptions,
  ttsHookOptions,
) {
  const [_, triggerAudioFile] = useMultiAudio(AUDIO[selectedAudio]?.value, audioHookOptions);
  const [__, triggerTTS] = useMakeTickerTTS(ttsHookOptions);

  return useCallback((tickers = []) => {
    if (selectedAudio === SPEAK_AUDIO_KEY) {
      triggerTTS(tickers);
    } else {
      triggerAudioFile();
    }
  }, [selectedAudio, triggerAudioFile, triggerTTS]);
}



function TopListScanner({ className }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [expressions, expressionPayload] = useRealtimeMappedExpressions();
  const { componentId, layoutId } = useContext(LayoutContext);
  const permissions = useUserPlanPermissions(['scanner_copy_paste', 'history_max_years']);
  const {
    gridColumnSizeKey,
    order = 'desc',
    orderby = 'session_chg_p',
    scannerHeight = 50,
    trackerHeight = 50,
    trackerOpen = false,
    audioOptions = defaultAudioOptions,
    trackerOptions = defaultTrackerOptions,
    cellFlashDisabled = false,
    [PROFILE_CONFIG.SCANNER_COLUMNS.idKey]: columnProfileId,
    [PROFILE_CONFIG.SCANNER_FILTERS.idKey]: filterProfileId,
  } = useSelector(selectComponent(componentId, layoutId));

  const triggerAudio = useMakeTriggerAudioOrTTS(
    audioOptions,
    { volume: audioOptions.volume, blocking: true, throttle: 250 },
    { volume: audioOptions.volume, bufferLimit: 3 }
  );


  const { dispatchUpdateLinkedData } = useToplistLinkedValues();

  /**
   * LOCALIZED. The actual current day, including weekends and holidays
   * @type {string}
   */
  const actualDay = formatMarketTime(getCurrentTradingDay(), 'yyyy-MM-dd');

  /**
   * LOCALIZED. String version, for useEffect dependency
   * @type {string}
   */
  const mostRecentTradingDayString = formatMarketTime(getMostRecentTradingDay(), 'yyyy-MM-dd');

  /**
   * LOCALIZED. Memoized. The default effective trading day to display, ignoring weekends/holidays, and 0-4am.
   * @type {Date}
   */
  const mostRecentTradingDay = useMemo(() => getMostRecentTradingDay(), [mostRecentTradingDayString]);

  /**
   * User selected historical day, as string. Default to most recent. Seperated from inputDay so we can set 'day' onPopoverClose.
   * @type {[string]}
   */
  const [day, setDay, dayRef] = useStateRef(formatMarketTime(mostRecentTradingDay, 'yyyy-MM-dd'));


  /**
   * If the chart shows the most recent data available. May not be "today" on the weekend or holiday
   **/
  const isViewingMostRecentData = dayRef.current === mostRecentTradingDayString;

  /**
   * The day selected in the filter UI window. Different to 'day' in order to perform error checking/validation.
   * @type {[Date]}}
   */
  const [inputDay, setInputDay, inputDayRef] = useStateRef(mostRecentTradingDay);

  /**
   * @type {Date}
   * LOCALIZED. The last day we have data for.
   */
  const oldestDateAvailable = useMemo(() => getOldestAllowedTopListDate(permissions.history_max_years), [mostRecentTradingDayString]);

  /**
   * watchlist tickers, for refreshing UI
   * @type {string[]}
   */
  const activeWatchlistTickerSet = useSelector(selectActiveWatchlistTickers);


  const [isFetching, setIsFetching] = useState(false);
  const columnProfilesList = useSelector(selectProfileList(PROFILE_CONFIG.SCANNER_COLUMNS.listKey));
  const filterProfilesList = useSelector(selectProfileList(PROFILE_CONFIG.SCANNER_FILTERS.listKey));
  const columnProfile = columnProfilesList.find(p => p.id === columnProfileId);
  const filterProfile = filterProfilesList.find(p => p.id === filterProfileId);
  const filterValues = filterProfile?.filters || {};


  const relevantExpressions = useFilterRelevantExpressions(expressions, columnProfile, filterProfile, orderby)

  const [trackerData, setTrackerData] = useState([]);

  useMemo(() => {
    // We need to sync the inputs when the day ticks over. Unfortunately this isn't very performant.
    // TODO: Refactor all the date stuff. We shouldn't need so many memos.
    setDay(format(mostRecentTradingDay, 'yyyy-MM-dd'));
    setInputDay(mostRecentTradingDay);
  }, [mostRecentTradingDayString])

  const {
    getExcludedTickers,
    addExcludedTicker,
    removeExcludedTicker,
  } = useMosaicTickerExclude(componentId, layoutId);

  const gridRef = useRef();
  const trackerGridRef = useRef();
  const scannerSplitRef = useRef();
  const trackerSplitRef = useRef();
  const audioNotificationTimes = useRef(new Map());
  const isMounted = useIsMountedRef();


  const handleSetResizeKey = useCallback((resizeKey) => {
    applyResize(gridRef, resizeKey);
    dispatch(updateComponent(componentId, layoutId, {
      gridColumnSizeKey: resizeKey
    }));
  }, [componentId, layoutId]);


  const setCellFlashDisabled = useCallback((newValue) => {
    dispatch(updateComponent(componentId, layoutId, {
      cellFlashDisabled: !!newValue
    }));
  }, [componentId, layoutId])


  const makeScannerMenu = useScannerContextMenu({
    addExcludedTicker,
    handleSetResizeKey,
    setCellFlashDisabled
  });


  const makeTrackerMenu = useScannerContextMenu({
    addExcludedTicker,
    handleSetResizeKey,
  });


  useEffect(() => {
    /* This is a work-around to avoid making <Split /> a controlled component, which is too sluggish through our layout redux store.
    Set it on mount, then let Split handle the state afterwords. Silently persist dragEnd result to db for next mount. */
    if (scannerSplitRef.current && trackerSplitRef.current && scannerHeight && trackerOpen) {
      scannerSplitRef.current.style.height = `${scannerHeight}%`;
      trackerSplitRef.current.style.height = `${trackerHeight}%`;
    }
  }, [scannerSplitRef.current, trackerSplitRef.current, trackerOpen]);


  useDeepCompareEffect(() => {
    // Refresh the grid when the watchlist changes, since we have little triangular indicators for active tickers
    if (isMounted.current && gridRef?.current?.api) {
      gridRef?.current?.api?.refreshCells({
        force: true, // calculated from context, the grid won't know to refresh when the watchlist changes.
        columns: ['ticker']
      });
    }
  }, [activeWatchlistTickerSet]);


  const trackerRows = useMemo(() => {
    const activeOptions = Object.keys(trackerOptions).filter(k => trackerOptions[k]);

    const rows = (() => {
      if (activeOptions.length === 0) return [];
      if (activeOptions.length === Object.keys(trackerOptions).length) return trackerData;

      // this is weird that we're deduping, but its to avoid:
      // ADDED: TSLA, ADDED: TSLA, ADDED: TSLA
      // That can be kind of confusing, without the REMOVED message.
      // TODO: Do we want this uniq?
      return _flow(
        fp_filter(row => activeOptions.includes(row.action)),
        fp_uniqBy('ticker')
      )(trackerData);
    })();


    let notifyTickers = [];

    let cooldownTime = audioOptions?.cooldownTime;
    if (!cooldownTime && cooldownTime !== 0) {
      cooldownTime = 60;
    }

    trackerData.forEach(({ ticker, batchTime, stale, action }) => {
      // DataSource sets 'stale' on previous items. Kind of hacky, but easier than messing
      // with timestamps

      if (action !== TRACKER_ACTIONS.added || !ticker || stale) return;

      if (notifyTickers.includes(ticker)) return;

      if (!audioNotificationTimes?.current?.has(ticker)) {
        notifyTickers.push(ticker)
        audioNotificationTimes?.current?.set(ticker, batchTime);
      } else {
        const lastPlayedAt = audioNotificationTimes?.current?.get(ticker);

        if (batchTime - lastPlayedAt >= cooldownTime) {
          notifyTickers.push(ticker);
          audioNotificationTimes?.current?.set(ticker, batchTime);
        }
      }
    });

    if (notifyTickers.length) {
      triggerAudio(notifyTickers);
    }

    return rows;
  }, [trackerData, trackerOptions, triggerAudio, audioOptions?.duplicateCooldownMs]);


  const scannerColumns = useDeepCompareMemo(() => {
    // We set sort here for the initial load. The grid needs to be told what sort it starts with.
    // After that, the grid will then handle its sort internally. We will capture the value inside the dataSource,
    // and save it to the component, so we can set the sort on the next load.

    // If this memo fires again in the same component, it's fine if we set the sort here again. Not needed, but it doesn't hurt.
    const gridColumns = buildGridColumns(columnProfile.columns, GRID_COLUMNS, relevantExpressions);
    let sortColIdx = gridColumns.findIndex(c => c.field === orderby);
    if (sortColIdx !== -1) {
      gridColumns[sortColIdx].sort = order;
    }
    return gridColumns;
  }, [columnProfile.columns, relevantExpressions]);

  const memoizedDataSource = useMemo(() => {
    return new TopListDataSourceV2({ id: componentId });
  }, []);

  useSyncronizedTimer(() => {
    if (dayRef.current === actualDay && memoizedDataSource.initialRequestComplete) {
      setTimeout(() => {
        try {
          if (isMounted.current && gridRef.current) {
            gridRef.current?.api.refreshServerSide({ route: [], purge: false });
          }
        } catch (err) {
          Sentry.captureException(err);
        }
      }, 0);
    }
  });

  useDeepCompareEffect(() => {
    if (memoizedDataSource.initialRequestComplete) {
      setIsFetching(true);
      setTimeout(() => {
        if (gridRef.current) {
          try {
            if (isMounted.current && gridRef.current) {
              gridRef.current?.api.refreshServerSide({ route: [], purge: true });
            }
          } catch (err) {
            Sentry.captureException(err);
          }
        }
      }, 0);
    }
  }, [filterValues, day, columnProfile.columns, relevantExpressions]);

  useEffect(() => {
    if (memoizedDataSource.initialRequestComplete) {
      setTimeout(() => {
        if (isMounted.current && gridRef.current) {
          try {
            gridRef.current?.api.refreshServerSide({ route: [], purge: false });
          } catch (err) {
            Sentry.captureException(err);
          }
        }
      }, 0);
    }
  }, [getExcludedTickers]);

  const handleDateDropdownClose = () => {
    // guaranteed to be OK, based on KeyboardDatePicker logic
    if (isMounted.current) {
      setDay(format(inputDayRef.current, 'yyyy-MM-dd'));
    }
  };

  const handleSetTrackerData = useCallback((data) => {
    if (!data || !data.length) {
      setTrackerData([]);
      audioNotificationTimes?.current?.clear();
    } else {
      setTrackerData(data);
    }
  }, []);

  const handleColumnProfileSubmit = useCallback(({ expressions, ...profile }) => {
    dispatch(updateScannerColumnProfiles(profile, layoutId, componentId, expressionPayload(expressions)));
  }, [layoutId, componentId]);

  const handleFilterProfileSubmit = useCallback(({ expressions, ...profile }) => {
    dispatch(updateScannerFilterProfiles(profile, layoutId, componentId, expressionPayload(expressions)));
  }, [layoutId, componentId]);

  const handleDrawerDragEnd = (newScannerHeight, newTrackerHeight) => {
    if (scannerHeight !== newScannerHeight || trackerHeight !== newTrackerHeight) {
      dispatch(updateComponent(componentId, layoutId, {
        scannerHeight: newScannerHeight,
        trackerHeight: newTrackerHeight,
      }));
    }
  };

  const toggleTrackerDrawerOpen = () => {
    dispatch(updateComponent(componentId, layoutId, { trackerOpen: !trackerOpen }));
  };

  const handleRowClick = useCallback(({ data }) => {
    if (data) {
      const historicalDate = isViewingMostRecentData ? false : dayRef.current;
      dispatchUpdateLinkedData({
        ticker: data.ticker,
        ssr: data.ssr,
        historicalDate
      });
    }
  }, [dispatchUpdateLinkedData, isViewingMostRecentData]);

  const handlePersistSort = ({ order: newOrder, orderby: newOrderby }) => {
    if (newOrder !== order || newOrderby !== orderby) {
      dispatch(updateComponent(componentId, layoutId, {
        order: newOrder,
        orderby: newOrderby
      }));
    }
  };

  const getRowId = useCallback(({ data }) => data.ticker, []);

  const onGridReady = useCallback(({ api }) => {
    setIsFetching(true);
    api?.setServerSideDatasource(memoizedDataSource);
  }, [memoizedDataSource]);

  const popupParent = useMemo(() => {
    return document.querySelector('body');
  }, []);

  const getTrackerRowId = useCallback(({ data }) => `${data.ticker}#${data.timestamp}`, []);

  const handleTrackerOptionsChange = useCallback((event) => {
    dispatch(updateComponent(componentId, layoutId, {
      trackerOptions: {
        ...trackerOptions,
        [event.target.name]: event.target.checked
      }
    }));
  }, [trackerOptions]);

  const handleAudioOptionsChange = useCallback((newOptions) => {
    dispatch(updateComponent(componentId, layoutId, {
      audioOptions: {
        ...audioOptions,
        ...newOptions
      }
    }));
  }, [audioOptions]);


  return (
    <MosaicPanel
      className={clsx(className, classes.root)}
      variant={isViewingMostRecentData ? null : 'yellow'}
    >
      <MosaicPanelHeader
        loading={isFetching}
        title={filterProfile.name}
        titleSuppliment={day === actualDay ? 'today' : day}
        titleSupplimentColor={alpha(day === actualDay ? theme.palette.text.positive : theme.palette.text.secondary, .7)}
        align="center"
      >
        <IntercomArticleButton
          articleId={INTERCOM_ARTICLES?.toplist?.components?.scanner}
        />
        <TickerExcludePopover
          onSelect={addExcludedTicker}
          onRemove={removeExcludedTicker}
          getExcludedTickers={getExcludedTickers}
        />
        <SlicedColumnsForm
          profiles={columnProfilesList}
          activeProfile={columnProfileId}
          expressions={expressions}
          onSubmit={handleColumnProfileSubmit}
        />
        <SlicedFiltersForm
          profiles={filterProfilesList}
          activeProfile={filterProfileId}
          expressions={expressions}
          onSubmit={handleFilterProfileSubmit}
          oldestDateAvailable={oldestDateAvailable}
        />
        <SmallFilterWindow
          iconText="History"
          Icon={MarketOpenIcon}
          iconColor={isViewingMostRecentData ? theme.palette.text.primary : theme.palette.historical.icon}
          shouldHideIconText={true}
          popoverMinWidth={392}
          noGutters
          backgroundOpacity={.3}
          popoverOnClose={handleDateDropdownClose}
          closeOnEnter
        >
          <InlineKeyboardDatePicker
            format="yyyy-MM-dd"
            placeholder="yyyy-mm-dd"
            date={inputDay}
            onAccept={setInputDay}
            marketTime
            disableWeekend
            disableHoliday
            maxDate={mostRecentTradingDay}
            minDate={oldestDateAvailable}
            makeStaticRanges={makeStaticDaysTopList}
          />
        </SmallFilterWindow>
      </MosaicPanelHeader>
      <Split
        onDragEnd={handleDrawerDragEnd}
        mode="vertical"
        renderBar={({ onMouseDown, ...props }) => {
          return (
            <div  {...props} style={{ height: 22 }}>
              <ScannerTrackerBar
                open={trackerOpen}
                trackerOptions={trackerOptions}
                onTrackerOptionsChange={handleTrackerOptionsChange}
                audioOptions={audioOptions}
                onAudioOptionsChange={handleAudioOptionsChange}
                onMouseDown={onMouseDown}
                onClick={toggleTrackerDrawerOpen}
              />
            </div>
          );
        }}
      >
        <MosaicPanelBody
          ref={scannerSplitRef}
          loading={isFetching}
          className={clsx(classes.gridContainer, 'ag-theme-ett', 'ag-theme-no-row-selection')}
        >
          <AgGridReact
            ref={gridRef}
            context={{
              columnProfile,
              filterProfile,
              day,
              setIsFetching,
              order,
              orderby,
              trackerData,
              handleSetTrackerData,
              handlePersistSort,
              activeWatchlistTickerSet,
              handleSetResizeKey,
              getExcludedTickers,
              cellFlashDisabled,
              isMountedRef: isMounted,
              expressions
            }}
            columnDefs={scannerColumns}
            rowSelection={'single'}
            onRowSelected={(params) => onRowSelected(params, handleRowClick)}
            onRowClicked={handleRowClick}
            navigateToNextCell={handleKeyboardRowNavigation}
            suppressServerSideInfiniteScroll={true}
            serverSideSortOnServer={true}
            suppressMultiSort={true}
            animateRows={true}
            headerHeight={25}
            getContextMenuItems={makeScannerMenu}
            rowClassRules={rowClassRules}
            getRowId={getRowId}
            popupParent={popupParent}
            sortingOrder={['desc', 'asc', 'abs']}
            serverSideInitialRowCount={50}
            cacheBlockSize={50}
            maxBlocksInCache={1}
            rowBuffer={0}
            rowModelType="serverSide"
            // loadingCellRenderer={LoadingCellSkeleton}
            loadingCellRenderer={() => <span />}
            components={{
              'animateChangeCellRenderer': AnimateChangeCellRenderer,
              'compactNumberCellRenderer': CompactNumberCell,
            }}
            onGridReady={onGridReady}
            onFirstDataRendered={() => {
              if (gridColumnSizeKey) {
                applyResize(gridRef, gridColumnSizeKey);
              }
            }}
          />
        </MosaicPanelBody>
        {trackerOpen && (
          <div
            ref={trackerSplitRef}
            className={classes.trackerDrawer}
          >
            <div className={clsx(classes.drawerGridCont, 'ag-theme-ett', 'ag-theme-wl-tracker')}>
              <AgGridReact
                ref={trackerGridRef}
                rowData={trackerRows}
                columnDefs={TRACKER_COLUMNS}
                rowSelection={'single'}
                popupParent={popupParent}
                onRowSelected={(params) => onRowSelected(params, handleRowClick)}
                onRowClicked={handleRowClick}
                navigateToNextCell={handleKeyboardRowNavigation}
                animateRows={true}
                headerHeight={0}
                getContextMenuItems={makeTrackerMenu}
                noRowsOverlayComponent={SimpleMessageOverlay}
                noRowsOverlayComponentParams={{
                  message: 'No changes to show'
                }}
                getRowId={getTrackerRowId}
                rowModelType="clientSide"
              />
            </div>
          </div>
        )}
      </Split>
      {!trackerOpen && (
        <ScannerTrackerBar
          open={trackerOpen}
          options={trackerOptions || defaultTrackerOptions}
          onClick={toggleTrackerDrawerOpen}
        />
      )}
    </MosaicPanel>
  );
}

export default TopListScanner;
