import { STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { EXPR_PREFIX } from 'src/redux/expressions/globalExpressionReducer';
/**
 * Like filterReducer/formTreeManipulation, this function is a way to modify the tree.
 *
 * The difference is, this is done in Profile structure. Sometimes, we need to modify
 * data on load, or on redux. Like deleting bad references to expressions.
 *
 * Right now, this is intended to be used with immer.
 *
 * TODO: This is garbage. Having to write 2 or 3 versions of every state update is stupid. Options:
 *  1) Normalize state in the database. Define only a formToQuery. Form will be default.
 *  2) Map Profile into Form before modifying. Then I guess we can run the reducer on it. This is
 *      actually an interesting idea..
 *  Thats really all I can think of...
 **/



/**
 * Deleting nodes from the Profile sturcture is tough.
 * This is a doubly-linked tree, to assist with these recursive updates.
 *
 * Convert a Profile to this, delete your nodes, then convert back.
 **/
export class TreeNode {
  constructor({
    type,
    ...rest
  }) {
    this.type = type;
    /** @type {TreeNode[]} **/
    this.children = [];
    this.data = rest;
    /** @type {TreeNode|null} **/
    this.parent = null;
  }

  /** @param {(node: TreeNode) => void} callback **/
  forEach(callback) {
    // Use a stack and reversed access to allow mutability
    const stack = [this];
    while (stack.length > 0) {
      const node = stack.pop();
      callback(node);
      for (let i = node.children.length - 1; i >= 0; i--) {
        stack.push(node.children[i]);
      }
    }
  }

  /** @param {TreeNode} node **/
  addChild(node) {
    node.parent = this;
    this.children.push(node);
  }

  deleteNode() {
    if (this.parent) {
      const index = this.parent.children.indexOf(this);
      if (index > -1) {
        this.parent.children.splice(index, 1);
      }
    }
  }

  deleteParent() {
    if (this.parent) {
      this.parent.deleteNode();
    }
  }

  replaceWith(newChild) {
    const index = this.parent.children.indexOf(this);
    if (index > -1) {
      this.parent.children[index] = newChild;
      newChild.parent = this.parent;
      this.parent = null;
    }
  }

  /** @returns {TreeNode} **/
  static fromProfile(obj) {
    const type = decideNodeType(obj);
    if (!type) return null;

    const { [type]: _, ...rest } = obj;

    const node = new TreeNode({ type, ...rest });

    (obj?.[type] || []).forEach(child => {
      const childNode = TreeNode.fromProfile(child);
      node.addChild(childNode);
    })

    return node;
  }

  /** @returns {ProfileStruct} **/
  toProfile() {
    const obj = { ...this.data };
    if (this.type !== STRUCTURAL_TYPES.FILTER) {
      obj[this.type] = this.children.map(child => child.toProfile()).filter(Boolean);
    }
    return obj;
  }
}


/**
 * Replicates formReducer/DELETE_ENTITY
 * @see src/app/slicedForm/FilterForm/reducers/filterReducer.js
 * @param {TreeNode} node
 * @returns {undefined}
 **/
export const deleteEntity = (node) => {

  const recursivlyModifyParentIfNeeded = (parent) => {
    if (!parent || !parent?.type || parent.type === STRUCTURAL_TYPES.AND) return;

    const nextParent = parent.parent;

    let hasChanged = false;

    if (parent.children.length === 0) {
      parent.deleteNode();
      hasChanged = true;
    }
    else if (parent.children.length === 1) {
      const childToKeep = parent.children[0];
      parent.replaceWith(childToKeep);

      if (childToKeep.type === STRUCTURAL_TYPES.FILTER && childToKeep?.data?.startTime) {
        delete childToKeep.data.startTime
      }

      hasChanged = true;
    }

    if (hasChanged) {
      recursivlyModifyParentIfNeeded(nextParent);
    }
  }

  if (node?.parent && node.parent?.type === STRUCTURAL_TYPES.SLICE_GROUP && node?.parent.children?.length) {
    if (node.parent.children.indexOf(node) === 0) {
      const parentParent = node.parent?.parent;

      node.parent.deleteNode();
      recursivlyModifyParentIfNeeded(parentParent);
      return;
    }
  }

  const parent = node.parent;

  node.deleteNode();
  recursivlyModifyParentIfNeeded(parent);
  return;
}


/**
 * @param {object} arg
 * @returns {boolean}
 **/
export const argHasExpression = (arg = {}) => {
  if (arg?.left?.column.startsWith(EXPR_PREFIX)) return true;
}


/**
 * @param {object} arg
 * @param {string[]} expressions
 **/
export const argHasSpecificExpression = (arg = {}, expressions = []) => {
  return (arg?.column?.startsWith(EXPR_PREFIX) && expressions.includes(arg?.column))
}


/**
 * @param {TreeNode} filterNode
 * @returns {boolean}
 **/
export const filterNodeArgContains = (filterNode, predicate) => {
  if (filterNode?.type !== STRUCTURAL_TYPES.FILTER) return false;

  const args = [filterNode?.data?.left, ...filterNode?.data?.right].filter(Boolean);
  return args.some(predicate);
}




/**
 * @param {object} node
 * @returns {keyof STRUCTURAL_TYPES}
 */
export const decideNodeType = (node) => {
  if (!node || !(typeof node === 'object')) return null;

  if ('operator' in node) return STRUCTURAL_TYPES.FILTER;
  if (STRUCTURAL_TYPES.AND in node) return STRUCTURAL_TYPES.AND;
  if (STRUCTURAL_TYPES.OR in node) return STRUCTURAL_TYPES.OR;
  if (STRUCTURAL_TYPES.SLICE_GROUP in node) return STRUCTURAL_TYPES.SLICE_GROUP;

  return null;
};

