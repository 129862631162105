import React, { createContext, useContext, useMemo, useState } from 'react';
import { NODE_POSITIONS, STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { CREATE_FILTER, CREATE_OR_GROUP } from 'src/app/slicedForm/FilterForm/reducers/filterReducer';
import { useFormSelector } from 'src/app/slicedForm/shared/context/FormProvider';
import { selectActiveProfile } from 'src/app/slicedForm/shared/reducers/profileReducer';
import { getFilterEntityDefaults } from 'src/app/slicedForm/shared/schema/schemaBuilder';


const defaultEntityValue = {
  /** ID of entity */
  id: null,
  /** Type of entity */
  type: null,
  /** Generic. Probably inheriting from ProfileLockedProvider, unless I changed the code. */
  disabled: false
};

const defaultGroupValue = {
  /** ID of nearest parent group, could be itself */
  groupId: null,
  /** Type of nearest parent group, could be itself */
  groupType: null,
  /** Callback to decide how to handle creating a new OR filter. Has many implementations */
  onOrClick: (id, dispatch) => { },
};

export const FormEntityContext = createContext(defaultEntityValue);

export const FormGroupContext = createContext(defaultGroupValue);


/**
 * Information about the Filter Element itself.
 * Default values are invalid.
 */
export function useFormEntityContext() {
  const context = useContext(FormEntityContext);
  if (context === defaultEntityValue) {
    throw new Error('useFormEntityContext must be used within a FormEntityContextProvider');
  }
  return context;
}

/**
 * Information about the Parent group. (AND/OR/SLICE)
 * Applies recursively. So a SLICE group may have an AND/OR parent. And OR group will have an AND parent.
 * The root AND group will have no parent, and thus null values.
 *
 * Default values are valid.
 */
export function useFormGroupContext() {
  return useContext(FormGroupContext);
}


export function FormEntityProvider({ id, type, disabled, children }) {
  const index = useEntityIndexRelativeToFirstGroup(id);

  const value = useMemo(() => {
    let position;

    if (!index) {
      position = null;
    } else if (index?.index === index?.total - 1) {
      position = NODE_POSITIONS.LAST;
    } else if (index?.index === 0) {
      position = NODE_POSITIONS.FIRST;
    } else {
      position = NODE_POSITIONS.MIDDLE;
    }

    return {
      id,
      type,
      position,
      disabled,
    };
  }, [id, type, disabled, index?.index, index?.total]);

  return (
    <FormEntityContext.Provider value={value}>
      {children}
    </FormEntityContext.Provider>
  );
}


/**
 * Get the index of a FILTER, relative to its containing OR group.
 * We need these to know if an element is VISUALLY first or last in its group.
 *
 * This can't be derived easily, since ORs and SLICEs are arbitrarily nested.
 * A filters direct children aren't necissarily the entire story.
 *
 * We attempted a context+counter solution and it was untennable. This is still bad
 * because we need to select the 'profile.root', but it's faster than the alternative.
 * 
 * TODO:
 * Only other option I can concieve of is manually computing this in filterReducer.js on every ACTION,
 * and storing it to an entityPositions Map. 
 * That would likely be faster. Dispatches happen less often than renders.
 */
function useEntityIndexRelativeToFirstGroup(entityId) {
  const profile = useFormSelector(selectActiveProfile);
  return findIndexInOrGroup(entityId, profile.root);
}


function findIndexRecursively(node, currentIndex, filterId) {
  if (node.type === STRUCTURAL_TYPES.FILTER) {
    if (node.id === filterId) {
      currentIndex.index = currentIndex.total;
    }
    currentIndex.total++;
  }

  if (node.tree && Array.isArray(node.tree)) {
    for (const childNode of node.tree) {
      findIndexRecursively(childNode, currentIndex, filterId);
    }
  }

  return null;
}


/**
 * @param {string} filterId
 * @param {Object} root
 * @return {{total: number, index: null}|null}
 */
function findIndexInOrGroup(filterId, root) {
  for (const node of root.tree) {
    let currentIndex = { index: null, total: 0 };
    findIndexRecursively(node, currentIndex, filterId);
    if (currentIndex.index !== null) {
      return currentIndex;
    }
  }

  return null;
}


/**
 * The action of clicking the OR button seems simple, but actually represents many different possibilites based on the
 * parent context.
 * The easiest way (I found) of implementing this is by using the currentGroup and parentGroup to decide the callback
 * functionality, and passing that callback down through Context.
 * 
 * Alternative would be to do this inside the reducer, but then we have to pass in all possible arguments. Seems worse.
 * 
 * @param {string} groupId
 * @param {keyof STRUCTURAL_TYPES} groupType
 * @param {string} parentGroupId
 * @param {keyof STRUCTURAL_TYPES} parentGroupType
 * @return {(function(string, Object, function): void)|*}
 */
export const getOrClickCallback = (groupId, groupType, parentGroupId, parentGroupType) => {

  const getFilterData = (colDef) => {
    if (!colDef || !Object.keys(colDef).length) return null
    return getFilterEntityDefaults(colDef);
  }

  if (groupType === STRUCTURAL_TYPES.AND && !parentGroupId) {
    // Base level AND. The first FILTER item gets placed into a new OR
    return (existingEntityId, newColDef, dispatch) => {
      const newEntityData = getFilterData(newColDef);
      dispatch({
        type: CREATE_OR_GROUP,
        payload: {
          id: existingEntityId,
          data: newEntityData
        },
      });
    };
  }
  if (groupType === STRUCTURAL_TYPES.OR) {
    // Easiest case, add another FILTER to the current OR group
    return (existingEntityId, newColDef, dispatch) => {
      const newEntityData = getFilterData(newColDef);
      dispatch({
        type: CREATE_FILTER,
        payload: {
          groupId,
          data: newEntityData
        }
      });
    };
  }
  if (groupType === STRUCTURAL_TYPES.SLICE_GROUP && parentGroupType === STRUCTURAL_TYPES.AND) {
    // Create an OR group, and make the entire SLICE_GROUP the first child
    return (existingEntityId, newColDef, dispatch) => {
      const newEntityData = getFilterData(newColDef);
      dispatch({
        type: CREATE_OR_GROUP,
        payload: {
          id: groupId,
          data: newEntityData
        }
      });
    };
  }
  if (groupType === STRUCTURAL_TYPES.SLICE_GROUP && parentGroupType === STRUCTURAL_TYPES.OR) {
    // Slice group already inside OR, add another filter to the parentGroup OR
    return (existingEntityId, newColDef, dispatch) => {
      const newEntityData = getFilterData(newColDef);
      dispatch({
        type: CREATE_FILTER,
        payload: {
          groupId: parentGroupId,
          data: newEntityData
        }
      });
    };
  }

  console.warn('getOrClick cannot resolve the proper callback', {
    groupId,
    groupType,
    parentGroupId,
    parentGroupType
  });

  throw new Error(`getOrClick cannot resolve the proper callback. See console logs.`);
};
