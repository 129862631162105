import edgeDataApi from 'src/apis/edgeDataApi';
import * as Sentry from '@sentry/react';
import profileToQuery from 'src/app/slicedForm/mapping/profileToQuery';
import { decideDataSourceSort } from 'src/app/components/grid/gridColumnFunctions';


export const FETCHING_STATUS = {
  FETCHING: 'is_fetching',
  PAGINATING: 'is_paginating',
  IDLE: 'idle'
};

// TODO: This probably shouldn't be hardcoded. See TopListGapStats.js
const BAR_CHART_METRICS = ['day0_pm_high_pct', 'day0_gap_pct', 'day0_chg_pct', 'day0_close_high_pct']


class TopListHistoricalDataSource {
  constructor(id) {
    this.id = id;
    this.initialRequestComplete = false;
  }

  async getRows(gridParams) {
    const { success, api, context } = gridParams;

    if (!context?.isMountedRef?.current && !api) return;

    const { setStats, setRecords, setFetchingStatus, isMountedRef } = context;

    const requestParams = this.buildQuery(gridParams);

    const isFirstRequest = requestParams.pagination.start_row === 0;

    let data;
    try {
      const response = await edgeDataApi.post('/history/component', requestParams);
      data = response.data;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      this.initialRequestComplete = true;

      if (!isMountedRef?.current) return;

      setFetchingStatus(FETCHING_STATUS.IDLE);
      success({ rowData: [], rowCount: 0 });
      setStats({});
      setRecords([]);
      if (!api) return;

      api?.showNoRowsOverlay();
      return;
    }

    if (!isMountedRef?.current || !api) return;

    const { records: recordData, stats } = data;
    const { data: records, total_count: totalCount } = recordData;

    if (isFirstRequest) {
      setStats(stats);
      setRecords(records);
    }

    success({
      rowData: records,
      rowCount: totalCount
    });

    if (isFirstRequest && !records.length) {
      api?.showNoRowsOverlay();
    } else {
      api?.hideOverlay();
    }

    this.initialRequestComplete = true;

    setFetchingStatus(FETCHING_STATUS.IDLE);

  }


  buildQuery(gridParams) {
    const { columnProfile, filterProfile, ticker, order, orderby, handlePersistSort, expressions = [] } = gridParams.context;
    const { startRow, endRow } = gridParams.request;

    const columns = [...new Set([
      ...columnProfile.columns,
      ...BAR_CHART_METRICS.map(m => ({ column: m }))
    ])].sort();

    const sortArgs = decideDataSourceSort(gridParams, { order, orderby }, ['day0_date'])
    handlePersistSort(sortArgs);

    const requestParams = profileToQuery(
      {
        filters: filterProfile.filters,
        columns,
        sortArgs
      },
      null,
      [],
      expressions
    );

    return {
      ...requestParams,
      ticker: ticker,
      pagination: {
        start_row: startRow,
        end_row: endRow
      },
    };
  }

}


export default TopListHistoricalDataSource;



