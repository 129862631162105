import React, { useState } from "react";
import clsx from 'clsx';
import { STICKY_OPTIONS } from "../definitions/inputEnums";
import { NODE_POSITIONS } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { DELETE_ENTITY } from "src/app/slicedForm/FilterForm/reducers/filterReducer";
import { useFormDispatch } from "src/app/slicedForm/shared/context/FormProvider";
import { useFormEntityContext, useFormGroupContext } from "../context/FormEntityContext";
import EntityIconButton from 'src/app/slicedForm/FilterForm/elements/EntityIconButton';
import EntityDoubleConfirmationIconButton from 'src/app/slicedForm/FilterForm/elements/EntityDoubleConfirmationIconButton';
import Popover from 'src/app/slicedForm/FilterForm/elements/Popover';
import ColumnPickerForm, { useColumnPickerFormWidth } from '../elements/ColumnPickerForm';
import { useEntity } from 'src/app/slicedForm/FilterForm/context/useFormEntity';
import { useColumnDef } from 'src/app/slicedForm/shared/context/ColumnDefsProvider';
import { CheckIcon } from 'src/theme/EdgeIcons';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Button,
  Typography,
  Box,
  useTheme,
  makeStyles,
} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
  root: {},
  tempButton: {
    minWidth: 36,
  }
}))


function FormEntityActions({ className }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useFormDispatch();
  const { id, position, disabled } = useFormEntityContext();
  const { groupId, onOrClick } = useFormGroupContext();
  const { useWatch } = useEntity(id);
  const column = useWatch('left.column');
  const colDef = useColumnDef(column);
  const popoverWidth = useColumnPickerFormWidth();
  const { bindTrigger, popoverApi, popoverProps } = Popover.usePopover({
    anchorPosition: 'right',
    maxWidth: popoverWidth
  });
  const [confirming, setConfirming] = useState(false);


  const handleAddOrGroupAndFilter = (colDef) => {
    popoverApi.handleClose();
    return onOrClick(id, colDef, dispatch);
  }

  const sticky = colDef?.sticky === STICKY_OPTIONS.STICKY;

  return (
    <Box
      className={clsx(className, classes.root)}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
    >
      <EntityIconButton
        aria-label="Add another 'OR' filter to this group"
        title="Add another 'OR' filter to this group"
        disableRipple
        disabled={disabled || position !== NODE_POSITIONS.LAST}
        text
        {...bindTrigger}
      >
        OR
      </EntityIconButton>
      <Popover {...popoverProps}>
        <ColumnPickerForm onSelect={handleAddOrGroupAndFilter} />
      </Popover>
      {!sticky ? (
        <EntityDoubleConfirmationIconButton
          confirming={confirming}
          BaseIcon={ClearIcon}
          baseLabel="Delete this filter"
          baseColor={theme.palette.primary.main}
          ConfirmIcon={CheckIcon}
          confirmLabel={'Confirm delete?'}
          confirmColor={theme.palette.text.negative}
          disabled={disabled}
          setConfirming={setConfirming}
          onAccept={() => dispatch({
            type: DELETE_ENTITY,
            payload: {
              id,
              groupId
            }
          })}
        />
      ) : (
        <EntityIconButton disabled />
      )}
    </Box>
  )
}


export default FormEntityActions;
