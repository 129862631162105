import { v4 as uuidv4 } from 'uuid';
import shortUuid from 'short-uuid';
import PromiseHelper from 'src/utils/PromiseHelper';

/**
 * ## Queue for resolving Top List Layout disconnect issues. ##
 * 
 * The database can get in an inconsistent state after a disconnect, since local changes in Redux
 * aren't reflected in either localstorage or the database, but Redux is used as the source of truth. 
 * Once onlinde, the offline patches are missed basically.
 * 
 * Using this, when we reconnect we will apply all patches sequentially. If patches fail, its no matter 
 * as when the user reloads the page, all unsaved changes will be lost, reverting to a previous valid state. 
 * If patches fail halfway though the stack, we should still be in a valid database state as order is guaranteed.
 * 
 * Side benifit of fixing out-of-order requests that can sometimes happen from lambda cold starts.
 * 
 * --- BEHAVIOR --- 
 * 
 * Requests are manually added to the queue.
 * - The queue only ever executes one request at a time. Guaranteed FIFO.
 * - When pushed to, the queue immediately begins processing all requests sequentially.
 * - It only stops processing when empty, or on error.
 * - On error: 
 *    - That particular request isn't removed from the queue. 
 *    - It stops processing the rest of the queue entirely. Adding another item will restart it.
 * 
 * - Responses are lost, as the promise is initiated within the queue. Thats okay for Layouts.
 *   If we needed responses, we'd have to implement a real Promise queue, with promises alredy active. See Auth retry logic.
 *   Or, you can 
 */

/**
 * 12/29 issues
 * 
 * New users are sending in "meta" updates alone as their first change.
 * This seems to suggest that the queue is clearing without saving to database.
 * 
 * 
 */


const temporaryID = () => shortUuid.generate().slice(0, 8);



class AsyncQueue {
  constructor() {
    this.queue = [];
    this.isProcessing = false;
    this.__onQueueComplete;
    this.__onQueueFailure;
    /** numer of times this.process() has ran, successfully or not. */
    this.processIndex = 0;
    /** number of times this.__onQueueComplete has been called. */
    this.batchIndex = 0;
    /** So we can track batches easier in Sentry */
    this.batchId = temporaryID();
  }

  /**
   * Add an item to the queue, and begin processing immediatly if not already processing.
   * If already processing, this will execute once the rest clears.
   * 
   * Any passed in callbacks will REPLACE the previous version. Only the most recent one will
   * ever be called. This is because we don't want our success handler to run once per item, 
   * but once per batch complete. Thats when we want to save to localstorage.
   * 
   * @param {function} callback - The callback to enqueue
   * @param {Object} [options = {}]
   * @param {function} [options.onQueueComplete] Callback to execute when the queue is empty
   * @param {function} [options.onQueueFailure] Callback to execute when the queue fails
   */
  push(callback, { onQueueComplete, onQueueFailure } = {}) {
    this.queue.push(callback);
    this.__onQueueComplete = onQueueComplete;
    this.__onQueueFailure = onQueueFailure;
    void this.process();
  }

  async process() {
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;


    const successes = [];


    while (this.queue.length) {
      try {
        const result = await this.queue[0]();
        successes.push(result);
        this.queue.shift();

        if (successes.length >= 5) {
          // add a little cooldown if we have a large backlog
          await PromiseHelper.sleepBetween(200, 500);
        }

      } catch (err) {
        console.error(`[AsyncQueue] Error after processing ${successes.length} items`, err);
        void this.__onQueueFailure?.({
          processIndex: this.processIndex,
          batchIndex: this.batchIndex,
          batchId: this.batchId,
          successes,
          error: err
        });
        this.processIndex++;
        this.isProcessing = false;
        return;
      }
    }

    void this.__onQueueComplete?.({
      processIndex: this.processIndex,
      batchIndex: this.batchIndex,
      batchId: this.batchId,
      successes,
      error: null
    });

    this.batchId = temporaryID();
    this.batchIndex++;
    this.processIndex++;
    this.isProcessing = false;
  }
}


export default AsyncQueue;
