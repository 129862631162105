import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntercom } from 'react-use-intercom';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import {
  makeStyles,
  Button,
} from '@material-ui/core';
import { QuestionMark } from 'src/theme/EdgeIcons';
import {
  HelpOutline as HelpOutlineIcon,
  Help as HelpIcon
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {}
}));



const INTERCOM_ARTICLES_CONFIG = {
  prod: {
    toplist: {
      components: {
        scanner: 9734004,
        watchlist: 9726518,
        news: 9726414,
        historical: 9731071,
        chart: 9728665,
        keystats: 9726502,
      }
    }
  },
  test: {
    toplist: {
      components: {
        scanner: 9731176,
        watchlist: 9731176,
        news: 9731176,
        historical: 9731176,
        chart: 9731176,
        keystats: 9731176,
      }
    }
  },
};



const stage = process.env.REACT_APP_INTERCOM_WORKSPACE_ENV || 'prod';


export const INTERCOM_ARTICLES = INTERCOM_ARTICLES_CONFIG[stage];


function IntercomArticleButton({
  className,
  articleId,
  disabled,
  text
}) {
  const classes = useStyles();
  const {
    showArticle,
  } = useIntercom();

  const handleClick = useCallback(() => {
    showArticle(articleId);
  }, [showArticle]);

  if (!(typeof articleId === 'number')) {
    return;
  }

  return (
    <PanelIconButton
      className={clsx(classes.root, className)}
      Icon={QuestionMark}
      text={text}
      shouldHideIconText
      disabled={disabled}
      ariaLabel={text}
      onClick={handleClick}
    />
  );
}


IntercomArticleButton.propTypes = {
  className: PropTypes.string,
  articleID: PropTypes.number,
  text: PropTypes.string,
  disabled: PropTypes.bool,
}


IntercomArticleButton.defaultProps = {
  disabled: false,
  text: 'help'
}


export default IntercomArticleButton;
