import React, { createContext, useContext } from 'react';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { REORDER_LEAF } from '../reducers/filterReducer';
import { STRUCTURAL_TYPES, } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { useFormDispatch } from 'src/app/slicedForm/shared/context/FormProvider';
import { useProfileLocked } from '../../shared/context/ProfileLockedProvider';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import { MoveIcon } from 'src/theme/EdgeIcons';
import {
  makeStyles,
} from '@material-ui/core';


/**
 * This file is a complete mess.
 * The main problem is that its really messy to wrap a component in a DragDropContext
 * without also altering that inner component. Its not a simple skin you can place over any old
 * object.
 * 
 * I attempted to do so, and it kind of works. But once we start allowing nested dragdrop, then
 * this solution isn't going to work.
 * 
 * Draggable props (especially dragHandleProps) props need an easier method of drilling. 
 * Drag children need to be dedicated components.
 */


const useStyles = makeStyles(theme => ({
  dragHandle: {
    display: 'flex',
    alignItems: 'center',
    '&.--disabled': {
      opacity: .7,
    },
    '&:hover svg': {
      color: theme.palette.text.primary,
    },
    '& svg': {
      color: '#777A82',
    }
  }
}));


const DragHandleContext = createContext(null);

/** Returns dragHandleProps. Default is allowed, its just "null" */
export const useDragHandleContext = () => useContext(DragHandleContext);



export const DRAGGABLE_CONFIG = {
  [STRUCTURAL_TYPES.AND]: {
    enabled: true,
    getDroppableId: (_groupId) => 'droppable_ROOT',
    getDraggableId: (id) => `draggable_ROOT_${id}`,
  },
  [STRUCTURAL_TYPES.OR]: {
    enabled: false,
    getDroppableId: (groupId) => `droppabe_OR_${groupId}`,
    getDraggableId: (id) => `draggable_OR_${id}`,
  },
  [STRUCTURAL_TYPES.SLICE_GROUP]: {
    enabled: false,
    getDroppableId: (groupId) => `droppabe_SLICE_${groupId}`,
    getDraggableId: (id) => `draggable_SLICE_${id}`,
  },
}


export function DragHandle({ className, ...rest }) {
  const classes = useStyles();
  const dragHandleProps = useDragHandleContext();
  const { locked } = useProfileLocked();

  return (
    <div
      className={clsx(
        className,
        classes.dragHandle,
        'dragHandle',
        locked && '--disabled'
      )}
      onClick={(e) => e.stopPropagation()}
      {...(dragHandleProps && dragHandleProps)}
      {...rest}
    >
      <MoveIcon />
    </div>
  )
}


const getListStyle = isDraggingOver => {
  return isDraggingOver ? { pointerEvents: 'none' } : {};
};


const getItemStyle = (provided, snapshot) => {
  const style = {
    userSelect: 'none',
    position: 'relative',
    ...provided.draggableProps.style,
    ...(snapshot.isDragging && { opacity: .6 })
    // cursor: snapshot.draggingOver ? "grabbing" : "not-allowed", // Not working. Can't figure out why
  };
  return style;
};



export function FilterGroupDraggableProvider({
  className,
  id,
  index,
  groupId,
  groupType,
  isDragDisabled,
  children
}) {

  if (!DRAGGABLE_CONFIG[groupType].enabled) {
    // reset so nested child undraggable components don't inherit
    return (
      <DragHandleContext.Provider value={null}>
        {children}
      </DragHandleContext.Provider>
    )
  }

  const key = DRAGGABLE_CONFIG[groupType].getDraggableId(id);

  return (
    <Draggable
      draggableId={key}
      index={index}
      isDragDisabled={isDragDisabled}
      key={key}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          style={getItemStyle(provided, snapshot)}
          className={className}
        >
          <DragHandleContext.Provider value={provided.dragHandleProps}>
            {children}
          </DragHandleContext.Provider>
        </div>
      )}
    </Draggable>
  )

}


export function FilterGroupDroppableProvider({
  groupId,
  groupType,
  children
}) {
  const dispatch = useFormDispatch();

  const onDragEnd = result => {
    if (!result.destination || !result.source) return;
    dispatch({
      type: REORDER_LEAF,
      payload: {
        groupId,
        sourceIndex: result.source.index,
        destIndex: result.destination.index,
      }
    })
  }

  if (!DRAGGABLE_CONFIG[groupType].enabled) return children

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={DRAGGABLE_CONFIG[groupType].getDroppableId(groupId)}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

